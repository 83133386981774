import React, { Component, useContext, useState, useEffect } from "react";

import {
  Card,
  IconButton,
  List,
  useTheme as paperTheme,
} from "react-native-paper";
import { View, Text } from "react-native";
import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  styles,
  whypeStyles,
} from "../utils/styles/main";
import { useAuth } from "../utils/AuthContext";

const ActivitiesComponent = ({ navigation }) => {
  const { recentActivity } = useAuth();
  // const [activities, setActivities] = useState([]);
  // useEffect(() => {
  //   const getActivities = async () => {
  //     // {
  //     //   title: str,
  //     //   description: str
  //     // }
  //     const data = await recentActivities();
  //     console.log("ACTIVITIES ", data);
  //     setActivities(data);
  //   };
  //   getActivities();
  // }, []);

  const takeAction = (action) => {
    if (action) {
      navigation.navigate(action.screen, action.options);
    } else {
      navigation.navigate("Notification");
    }
  };

  return (
    recentActivity && (
      <Card style={styles.card}>
        <Card.Content>
          <List.Item
            title={activity?.title || "Activity title"}
            description={activity?.message || "Activity message"}
            titleStyle={{ color: PRIMARY_COLOR }}
            style={{ margin: 0 }}
            key={index}
            right={(props) => (
              <IconButton
                {...props}
                icon="arrow-right"
                onPress={() => takeAction(activity.action)}
              />
            )}
          />
        </Card.Content>
      </Card>
    )

    // <View style={styles.recentActivities}>
    //   <Text>Recent activities </Text>
    //   <Text>Recent activities </Text>
    // </View>
  );
};

export default ActivitiesComponent;
