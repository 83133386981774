// Authhor: Mgregchi

const convertDate = (date) => {
  const d = new Date(date);
  // 'Tue Dec 05 2023'
  return d.toDateString();
};

const convertTime = (date) => {
  const d = new Date(date);
  return d.toLocaleTimeString();
};

const convertDateTime = (date) => {
  return `${convertDate(date)} ${convertTime(date)}`;
};

const getCurrentMonth = (date) => {
  const d = new Date(date);
  return d.toLocaleString("default", { month: "long" });
};

const getCurrentDateTime = () => {
  const d = new Date();
  return d.toLocaleString();
};

export {
  getCurrentDateTime,
  convertDateTime,
  convertDate,
  convertTime,
  getCurrentMonth,
};
