import React from "react";
import {
  Text,
  Dimensions,
  StyleSheet,
  View,
  Image,
  ImageBackground,
} from "react-native";
import { Badge, Card, IconButton, List, useTheme } from "react-native-paper";
import { SwiperFlatList } from "react-native-swiper-flatlist";
import { useAuth } from "../utils/AuthContext";
import { styles } from "../utils/styles/main";

const UpdatesAndPromo = () => {
  const { updatesAndPromo } = useAuth();

  return (
    <>
      {updatesAndPromo && updatesAndPromo.length > 0 && (
        <Card style={{ marginVertical: 15 }}>
          <Card.Content style={{ padding: 1 }}>
            <SwiperFlatList
              autoplay
              autoplayDelay={5}
              autoplayLoop
              index={0}
              showPagination
              data={updatesAndPromo}
              contentContainerStyle={{ padding: 0, margin: 0 }}
              renderItem={({ item }) => (
                <View style={componentStyle.child}>
                  <Image
                    style={componentStyle.image}
                    source={{ uri: item.image }}
                    //width={600}
                    //height={100}
                    //resizeMethod="scale"
                    //resizeMode="center"
                  />
                </View>
              )}
            />
          </Card.Content>
        </Card>
      )}
    </>
  );
};

const { width } = Dimensions.get("window");

const componentStyle = StyleSheet.create({
  child: { width: 600, justifyContent: "center", height: "100px" },
  text: { textAlign: "center" },
  image: { width: 600, height: 100 },
});

export default UpdatesAndPromo;
