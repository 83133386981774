import React, { useState, useRef } from "react";
import { View, ScrollView, RefreshControl, Alert } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import {
  Text,
  Button,
  Appbar,
  Card,
  Icon,
  Divider,
  List,
} from "react-native-paper";

import {
  SECONDARY_COLOR,
  PRIMARY_COLOR,
  styles,
  whypeStyles,
} from "../utils/styles/main";
import { useAuth } from "../utils/AuthContext";
import { SelectionComponent } from "../components/SelectionComponents";
import { StepIndicator } from "../components/BadgeComponent";

const colors = [PRIMARY_COLOR, SECONDARY_COLOR];

const BookingScreen = ({ navigation }) => {
  const { loadWhypePackages, isLoading } = useAuth();
  const [packages, setPackages] = useState([]);
  const [subTotal, setSubTotal] = useState();
  const totalSteps = 3;
  const completedSteps = 0;
  const [selectedPackage, setSelectedPackage] = useState(null);
  const colorIndexRef = useRef(0);

  const handlePackageSelection = (pickedPackage) => {
    if (selectedPackage?.name === pickedPackage.name) {
      setSelectedPackage(null);
      return;
    }
    setSelectedPackage(pickedPackage);
    setSubTotal(pickedPackage.price);
  };

  const handleBooking = () => {
    if (!selectedPackage) {
      Alert.alert("Please select a package");
      return;
    }
    navigation.navigate("Addons", {
      subTotal,
      selectedPackage: {
        id: selectedPackage.id,
        name: selectedPackage.name,
        price: selectedPackage.price,
      },
      totalSteps,
      completedSteps,
      purchaseType: "package",
    });
  };
  const loadSubscriptions = async () => {
    // Load subscriptions
    try {
      const { packages } = await loadWhypePackages();
      if (packages) {
        setPackages(packages);
      }
    } catch (error) {
      //console.log(error);
    }
  };

  React.useEffect(() => {
    loadSubscriptions();
  }, []);
  return (
    <SafeAreaView style={styles.safeArea}>
      <Appbar.Header statusBarHeight={0}>
        <Appbar.BackAction
          onPress={() => {
            navigation.navigate("WhypesList");
          }}
          accessibilityLabel="Go back"
        />

        <Appbar.Content title="Book a whype" titleStyle={{ fontSize: 14 }} />
        {/*
            <Appbar.Action icon="calendar" onPress={() => {}} />
            <Appbar.Action icon="magnify" onPress={() => {}} />
        */}
      </Appbar.Header>
      {packages.length > 0 && (
        <StepIndicator
          totalSteps={totalSteps}
          completedSteps={completedSteps}
        />
      )}
      {packages?.length > 0 && (
        <ScrollView
          refreshControl={
            <RefreshControl
              refreshing={isLoading}
              onRefresh={loadSubscriptions}
            />
          }
        >
          <View style={styles.container}>
            <Text variant="bodyLarge" style={styles.text.primary}>
              Select a package that best fits your needs.
            </Text>
            <Divider style={styles.regularDivider} />
            {packages?.map((item, index) => (
              <SelectionComponent
                key={index}
                onSelect={() => handlePackageSelection(item)}
                isSelected={selectedPackage?.name === item.name}
                colors={colors}
                colorIndexRef={colorIndexRef}
              >
                <Card.Title
                  title={item.name}
                  titleVariant="titleMedium"
                  titleStyle={[whypeStyles.cardTitle, styles.white]}
                  left={() =>
                    selectedPackage?.name === item?.name && (
                      <Icon
                        source="check-circle-outline"
                        size={30}
                        color="white"
                      />
                    )
                  }
                  subtitle={selectedPackage?.name === item?.name && "Selected"}
                  subtitleStyle={[styles.white, styles.underline]}
                  right={(props) => (
                    <Text
                      style={[styles.white, { marginRight: 8 }]}
                      variant="titleMedium"
                    >
                      N {item.price}
                    </Text>
                  )}
                />

                <ScrollView>
                  <Card.Content>
                    {item?.perks?.map((perk) => (
                      <View
                        style={[styles.row, styles.spaceBetween]}
                        key={perk.perk}
                      >
                        <Icon
                          source="check"
                          size={30}
                          color={styles.white.color}
                        />
                        <Text
                          variant="bodySmall"
                          style={[styles.white, { marginLeft: 8 }]}
                        >
                          {perk?.perk}
                        </Text>
                      </View>
                    ))}
                  </Card.Content>
                </ScrollView>
              </SelectionComponent>
            ))}
          </View>
          <View style={styles.btnContainer}>
            <Button
              style={styles.btn.primary}
              mode="contained"
              onPress={handleBooking}
              loading={isLoading}
              disabled={!selectedPackage}
              contentStyle={{ flexDirection: "row-reverse" }}
              icon="arrow-right-bold-circle"
              textColor="white"
            >
              Next
            </Button>
          </View>
        </ScrollView>
      )}
      {!packages.length > 0 && (
        <View style={styles.empty}>
          <Text style={[styles.text.secondary, styles.m3]}>
            Nothing here right now. Please refresh.
          </Text>
        </View>
      )}
    </SafeAreaView>
  );
};

export { BookingScreen };
