import React, { useState, useEffect } from "react";
import { View, ScrollView } from "react-native";
import { Text, Button, Tooltip, FAB } from "react-native-paper";
import ScheduleList from "./ScheduleList";
import AddScheduleForm from "./AddScheduleForm";
import {
  SECONDARY_COLOR,
  PRIMARY_COLOR,
  styles,
} from "../../utils/styles/main";
import { useTheme } from "../../utils/themes/ThemeContext";
import { useAuth } from "../../utils/AuthContext";

const ScheduleComponent = ({
  whypeName,
  whypeId,
  remainingWhypes,
  navigation,
}) => {
  const { theme } = useTheme();
  const [newWhypeName, setWhypeName] = useState(whypeName);

  const [schedules, setSchedules] = useState([]);
  const [newScheduleAdditionalInfo, setNewScheduleAdditionalInfo] =
    useState("");
  const [newScheduleDay, setNewScheduleDay] = useState("");
  const [newScheduleTime, setNewScheduleTime] = useState("");

  const [addNew, setAddNew] = useState(false);
  const { isLoading, scheduleWhype, getSchedules, App, setMessage } = useAuth();
  const [loadSchedules, setLoadSchedules] = useState(false);
  //const [schedulesChanged, setSchedulesChanged] = React.useState(false);

  useEffect(() => {
    const fetchSchedules = async () => {
      const { remoteSchedules } = await getSchedules({ whypeId });
      if (remoteSchedules) {
        console.log("Under if remote schedules ", remoteSchedules);
        setSchedules(remoteSchedules);
        //setInitialSchedulesLength(remoteSchedules.length);
      }
    };
    fetchSchedules();
  }, [loadSchedules]);

  const handleWhypeRename = async () => {
    if (newWhypeName && newWhypeName.length > 3) {
      const { success } = await scheduleWhype({
        whypeId,
        renameWhype: newWhypeName,
      });
      if (success) {
        navigation.navigate("Whypes");
      } else {
        //setMessage(message);
      }
    }
    return;
  };
  const handleAddNewSchedule = async () => {
    //newSchedule, deleteSchedule, deleteAll
    if (newScheduleDay && newScheduleTime) {
      //const { date, time, additionalInformation } = schedule;
      const [hours, minutes] = newScheduleTime.split(":");
      const dateTime = new Date(newScheduleDay);
      dateTime.setHours(
        parseInt(hours) + (newScheduleTime.includes("PM") ? 12 : 0)
      );
      dateTime.setMinutes(parseInt(minutes));
      const getTimezone = () => {
        const timezone = dateTime.toTimeString().split(" ")[1];
        return timezone;
      };
      const newSchedule = {
        date: newScheduleDay,
        time: newScheduleTime,
        timezone: getTimezone(),
        datetime: dateTime,
        note: newScheduleAdditionalInfo,
        whype: whypeId,
      };
      const { success } = await scheduleWhype({
        whypeId,
        newSchedule,
      });
      if (success) {
        navigation.navigate("Whypes");
        setLoadSchedules((prev) => !prev);
      } else {
        //setMessage(message);
      }
    }
  };

  const handleDeleteSchedule = async ({ scheduleId }) => {
    const { success } = await scheduleWhype(
      scheduleId
        ? {
            whypeId,
            deleteSchedule: scheduleId,
          }
        : {
            whypeId,
            deleteAll: true,
          }
    );
    if (success) {
      //navigation.navigate("Whypes");
      console.log("Schedule delete is success - ", success);
      setLoadSchedules((prev) => !prev);
    } else {
      //setMessage(message);
    }
  };

  return (
    <View style={styles.flex1}>
      {/* ... (other components) */}
      {schedules && schedules.length > 0 ? (
        <>
          <ScheduleList
            newWhypeName={newWhypeName}
            whypeName={whypeName}
            setWhypeName={setWhypeName}
            schedules={schedules}
            handleDeleteSchedule={handleDeleteSchedule}
            styles={styles}
            theme={theme}
            cannotAdd={schedules.length >= remainingWhypes}
            supportPhone={App?.phone}
            addNew={addNew}
            setAddNew={setAddNew}
            handleWhypeRename={handleWhypeRename}
          />
        </>
      ) : (
        <View style={[styles.textCenter, styles.contentCenter, styles.flex1]}>
          <Text style={styles.text.secondary} variant="bodySmall">
            You have no schedules yet for this whype
          </Text>
          {!addNew && (
            <Button
              icon="plus-circle"
              onPress={() => {
                setAddNew(!addNew);
              }}
              style={styles.btn}
            >
              Create new schedule
            </Button>
          )}
        </View>
      )}
      {addNew && (
        <View>
          <AddScheduleForm
            skipDays={App?.nonWorkingDays}
            workStartTime={App?.work_start_time}
            workEndTime={App?.work_end_time}
            newScheduleAdditionalInfo={newScheduleAdditionalInfo}
            setNewScheduleAdditionalInfo={setNewScheduleAdditionalInfo}
            newScheduleDay={newScheduleDay}
            setNewScheduleDay={setNewScheduleDay}
            newScheduleTime={newScheduleTime}
            setNewScheduleTime={setNewScheduleTime}
            handleAddNewSchedule={handleAddNewSchedule}
            style={styles}
            theme={theme}
            addNew={addNew}
            setAddNew={setAddNew}
            cannotAdd={schedules.length >= remainingWhypes}
            scheduleMessage={App?.schedule_message}
          />
        </View>
      )}

      {/* {schedules.length > 0 && (
        <>
          {!addNew && schedules && (
            <View style={[styles.btnContainer, styles.flex1]}>
              <Button
                accessibilityLabel="Set schedule"
                textColor="white"
                onPress={() =>
                  schedulesChanged
                    ? handleScheduleSubmit()
                    : navigation.goBack()
                }
                mode="contained"
                disabled={isLoading}
                loading={isLoading}
                style={[styles.actionBtn1, { margin: 10 }]}
              >
                {schedulesChanged ? "Save changes" : "Go back"}
              </Button>
            </View>
          )}
        </>
      )} */}
    </View>
  );
};

export { ScheduleComponent };
