import React, { useState } from "react";
import { View, ScrollView, StyleSheet } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Appbar, Button, Text, TextInput } from "react-native-paper";
import { useAuth } from "../../utils/AuthContext";

import {
  SECONDARY_COLOR,
  PRIMARY_COLOR,
  styles,
} from "../../utils/styles/main";
import ActionFeedback from "../../components/ActionFeedback";

const EmailChange = ({ navigation }) => {
  const { isLoading, user, changeEmail } = useAuth();

  const [currentEmail, setCurrentEmail] = useState(user.email);
  const [newEmail, setNewEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [passwordError, setPasswordError] = useState("");

  const handleEmailChange = async () => {
    // TODO: Implement email change logic
    const { message, error } = await changeEmail(newEmail, password);
    if (error) setPasswordError(error);
  };

  return (
    <SafeAreaView style={styles.safeArea}>
      <Appbar.Header statusBarHeight={0}>
        <Appbar.BackAction onPress={() => navigation.goBack()} />
        <Appbar.Content title="Change Email" titleStyle={{ fontSize: 14 }} />
      </Appbar.Header>
      <ActionFeedback />

      <ScrollView contentContainerStyle={{ flex: 1, justifyContent: "center" }}>
        <View style={styles.container}>
          <View style={styles.headingText}>
            <Text style={styles.text.primary} variant="bodySmall">
              We'll email you instructions on your new email. Please do not
              forget to verify the new email address to avoid being locked out
              of your account.
            </Text>
          </View>

          <Text style={styles.text.primary} variant="labelSmall">
            Current Email:
          </Text>
          <TextInput
            label="Current Email"
            value={currentEmail}
            onChangeText={setCurrentEmail}
            placeholder="Enter current email"
            mode="outlined"
            style={styles.input}
          />
          <Text style={styles.text.primary} variant="labelSmall">
            New Email:
          </Text>
          <TextInput
            label="New Email"
            value={newEmail}
            onChangeText={setNewEmail}
            placeholder="Enter new email"
            mode="outlined"
            style={styles.input}
          />
          {passwordError ? (
            <Text style={styles.text.danger} variant="labelSmall">
              {passwordError}
            </Text>
          ) : (
            <Text style={styles.text.primary} variant="labelSmall">
              You will need to confirm your password to change your email.
            </Text>
          )}
          <TextInput
            label="Password"
            value={password}
            onChangeText={setPassword}
            placeholder="Enter password to confirm"
            mode="outlined"
            style={styles.input}
            secureTextEntry={showPassword}
            left={<TextInput.Icon icon="form-textbox-password" />}
            right={
              <TextInput.Icon
                onPress={() => setShowPassword(!showPassword)}
                icon={showPassword ? "eye-off" : "eye"}
              />
            }
          />
        </View>
        <View style={styles.btnContainer}>
          <Button
            onPress={handleEmailChange}
            mode="contained"
            disabled={isLoading}
            loading={isLoading}
            style={[styles.actionBtn1, { margin: 10 }]}
            textColor="white"
          >
            Change Email
          </Button>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default EmailChange;
