import React from "react";

import { View, Text, ScrollView, FlatList } from "react-native";
import {
  Card,
  Divider,
  Appbar,
  Button,
  IconButton,
  Tooltip,
} from "react-native-paper";
import { styles, whypeStyles } from "../utils/styles/main";
import { SafeAreaView } from "react-native-safe-area-context";
//import {splash0, splash1} from '../utils/commonVars'
import { useAuth } from "../utils/AuthContext";

const NotificationScreen = () => {
  const {
    notifications,
    //getNotifications,
    deleteNotification,
  } = useAuth();

  const handleRemoveNotification = async (notificationId) => {
    await deleteNotification({ notificationId });
  };

  return (
    <SafeAreaView style={styles.safeArea}>
      <Appbar.Header statusBarHeight={0}>
        <Appbar.Content title="Notifications" titleStyle={{ fontSize: 14 }} />
        <Tooltip title="Delete all notifications">
          <Appbar.Action
            icon="delete-sweep-outline"
            onPress={() => deleteNotification({ notificationId: "__all__" })}
            accessibilityLabel={"Delete all"}
          />
        </Tooltip>
      </Appbar.Header>
      {notifications && notifications.length > 0 ? (
        <FlatList
          data={notifications}
          renderItem={({ item }) => (
            // Use swipe to deletec omponent
            <Card key={index} style={whypeStyles.card}>
              <Card.Title
                title={item.title}
                subtitle={item.time}
                right={(props) => (
                  <Tooltip title="Delete">
                    <IconButton
                      icon="delete"
                      accessibilityLabel="delete"
                      onPress={() => {
                        handleRemoveNotification(item.id);
                      }}
                    ></IconButton>
                  </Tooltip>
                )}
              />
              <Divider />
              <Card.Content>
                <Text style={styles.white} varaint={"bodySmall"}>
                  {item.message}
                </Text>
              </Card.Content>
            </Card>
          )}
          keyExtractor={(item) => item.toString()}
        />
      ) : (
        <View style={styles.empty}>
          <Text style={[styles.text.secondary, styles.m3]} varaint="bodyLarge">
            You don't have any notifications yet.
          </Text>
        </View>
      )}
    </SafeAreaView>
  );
};

export default NotificationScreen;
