// ScheduleList.js
import React from "react";
import { ScrollView, View } from "react-native";
import {
  DataTable,
  IconButton,
  Button,
  Text,
  List,
  Portal,
  Dialog,
  TextInput,
} from "react-native-paper";

const ScheduleList = ({
  schedules,
  handleDeleteSchedule,
  cannotAdd,
  supportPhone,
  styles,
  whypeName,
  setWhypeName,
  newWhypeName,
  addNew,
  setAddNew,
  handleWhypeRename,
}) => {
  const [dialogVisible, setDialogVisible] = React.useState(false);
  const showDialog = () => setDialogVisible(true);
  const hideDialog = () => setDialogVisible(false);
  const [scheduleId, setScheduleId] = React.useState("");
  const [promptMessage, setPromptMessage] = React.useState(
    "You are about deleting all schedules. Do you want to continue?"
  );
  //const [confirmDelete, setConfirmDelete] = React.useState(false);
  return (
    <>
      <Portal>
        <Dialog visible={dialogVisible} onDismiss={hideDialog}>
          <Dialog.Title>
            <Text variant="titleLarge">Confirm schedule delete</Text>
          </Dialog.Title>
          <Dialog.Content>
            <Text variant={"bodySmall"}>{promptMessage}</Text>
            <View style={styles.row}></View>
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              onPress={() => {
                hideDialog();
              }}
            >
              No, cancel
            </Button>
            <Button
              onPress={() => {
                hideDialog();
                handleDeleteSchedule({ scheduleId });
              }}
              //style={styles.actionBtn1}
              //mode="outlined"
            >
              Yes, continue
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>

      <ScrollView>
        <View>
          <TextInput
            label="Change whype nickname"
            value={newWhypeName}
            onChangeText={(value) => setWhypeName(value)}
            style={styles.input}
            mode="outlined"
            maxLength={50}
            focusable={true}
            right={
              <TextInput.Icon
                onPress={() => {
                  newWhypeName.length > 3 && newWhypeName !== whypeName
                    ? handleWhypeRename()
                    : "";
                }}
                icon={
                  newWhypeName.length > 3 && newWhypeName !== whypeName
                    ? "content-save-check-outline"
                    : ""
                }
              />
            }
          />
          <List.Accordion title="Schedule information">
            <Text variant="bodySmall">
              To edit a schedule, delete it and create a new one.
            </Text>
            <Text variant="bodySmall">
              {
                "Do not delete schedules that are 40 minutes close to the scheduled time."
              }
            </Text>
            <Text variant="bodySmall">
              {`In case of emergency, please contact us at ${supportPhone}.`}
            </Text>
          </List.Accordion>
        </View>
        <DataTable>
          <DataTable.Header>
            <DataTable.Title textStyle={{ margin: "auto" }}>
              Date
            </DataTable.Title>
            <DataTable.Title textStyle={{ margin: "auto" }}>
              Time
            </DataTable.Title>
            <DataTable.Title textStyle={{ margin: "auto" }}>
              <Button
                //icon="delete"
                onPress={() => {
                  //showDialog();
                  setPromptMessage(
                    `You are about deleting all ${schedules.length} schedules. Do you want to continue?`
                  );
                  showDialog();
                }}
              >
                Delete all
              </Button>
            </DataTable.Title>
          </DataTable.Header>
          {schedules.map((schedule, index) => (
            <DataTable.Row key={index} style={{ justifyContent: "flex-end" }}>
              <DataTable.Cell textStyle={{ margin: "auto" }}>
                {schedule.date}
              </DataTable.Cell>
              <DataTable.Cell textStyle={{ margin: "auto" }}>
                {schedule.time}
              </DataTable.Cell>
              <DataTable.Cell textStyle={{ margin: "auto" }}>
                <Button
                  icon="delete-circle"
                  onPress={() => {
                    setScheduleId(schedule.id);
                    setPromptMessage(
                      `You are about to delete ${schedule.date} ${schedule.time}. Do you want to continue?`
                    );
                    showDialog();
                  }}
                >
                  Delete
                </Button>
              </DataTable.Cell>
            </DataTable.Row>
          ))}
        </DataTable>
      </ScrollView>
      <View style={styles.alignEnd}>
        <Button
          icon={!addNew ? "plus-circle" : "minus-circle"}
          onPress={() => setAddNew(!addNew)}
          contentStyle={{ flexDirection: "row-reverse" }}
        >
          {!addNew ? "Add new" : "Hide"}
        </Button>
      </View>
    </>
  );
};

export default ScheduleList;
