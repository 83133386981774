import React, { useState } from "react";

import {
  View,
  ScrollView,
  Share,
  RefreshControl,
  Pressable,
} from "react-native";
import {
  Divider,
  Text,
  Menu,
  Tooltip,
  Button,
  ToggleButton,
  Avatar,
  List,
  IconButton,
  Portal,
  Dialog,
} from "react-native-paper";
import { useTheme } from "../utils/themes/ThemeContext";
//import { WebView } from "react-native-webview";
import { webResource } from "../utils/WebJob";
import { useAuth } from "../utils/AuthContext";

import { styles, profileStyles, SECONDARY_COLOR } from "../utils/styles/main";
import ActionFeedback, { UiAlert } from "../components/ActionFeedback";

import { SafeAreaView } from "react-native-safe-area-context";
import * as ImagePicker from "expo-image-picker";

//import { LocationPermissionsButton } from "../components/LocationManager";

const ProfileScreen = ({ navigation }) => {
  const { isDark, toggleTheme } = useTheme();
  const {
    refreshUser,
    logout,
    user,
    sendEmailVerification,
    isLoading,
    currentMonthWhypes,
    updateProfile,
    App,
  } = useAuth();
  const [visible, setVisible] = React.useState(false);
  //const [refreshing, setRefreshing] = React.useState(false);

  const [verificationMessage, setVerificationMessage] = React.useState(
    "Click to verify email"
  );
  const [emailSent, setEmailSent] = React.useState(false);
  const showDialog = () => setVisible(true);

  const hideDialog = () => setVisible(false);
  const [dpVisible, setDpVisible] = React.useState(false);

  const showDpDialog = () => setDpVisible(true);
  const hideDpDialog = () => setDpVisible(false);
  const [profilePicture, setProflePicture] = useState(user?.photoURL);

  const [useProfilePicture, setUseProfilePicture] = useState(true);

  const handleImagePick = async () => {
    hideDpDialog();

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true,
    });

    if (!result.canceled) {
      // Handle the selected image (e.g., send a POST request to the server)
      setUseProfilePicture(true);
      setProflePicture(result.assets[0]?.uri);
      try {
        const success = await updateProfile({
          photoURL: result.assets[0],
        });
        if (!success) {
          setUseProfilePicture(true);
          setProflePicture(null);
        }
      } catch (error) {}
    } else {
      UiAlert("Sorry, we need image permissions to make this work!");
    }
  };

  const handleRemovePicture = async () => {
    try {
      await updateProfile({ photoURL: "delete" });
    } catch (error) {}
    setUseProfilePicture(false);
    setProflePicture(null);
    hideDpDialog();
  };

  const refreshProfile = async () => {
    try {
      //setRefreshing(true);
      await refreshUser();
      //setRefreshing(false);
    } catch (error) {
      console.error(error);
      //setRefreshing(false);
    }
  };

  // Load the initial data when the screen loads
  React.useEffect(() => {
    refreshProfile();
  }, []);

  const links = App?.resources;

  const onShare = async () => {
    try {
      const result = await Share.share({
        message: App?.app_share.message,
        url: App?.app_share.url,
        title: App?.app_share.title,
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      UiAlert(error.message);
    }
  };
  const verifyEmail = () => {
    sendEmailVerification();
    setVerificationMessage("Verification email sent");
    setEmailSent(true);
  };
  const getInitials = (fullName) => {
    const splitNames = fullName.split(" ");

    return `${splitNames[0].charAt(0)}${splitNames[1].charAt(0)}`;
  };
  return (
    <SafeAreaView style={styles.safeArea}>
      <ActionFeedback />
      <ScrollView
        refreshControl={
          <RefreshControl refreshing={isLoading} onRefresh={refreshProfile} />
        }
      >
        <Portal>
          <Dialog visible={dpVisible} onDismiss={hideDpDialog}>
            <Dialog.Title>
              <Text variant="titleLarge">Profile Picture Options</Text>
            </Dialog.Title>
            <Dialog.Content style={styles.row}>
              <Button onPress={handleRemovePicture}>Remove Picture</Button>
              <Button onPress={handleImagePick}>Change Picture</Button>
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={hideDpDialog}>Cancel</Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
        <View style={profileStyles.container}>
          {/* Section 1: User Information */}
          <View style={[profileStyles.section, profileStyles.row]}>
            <Pressable
              onPress={() => {
                profilePicture ? showDpDialog() : handleImagePick();
              }}
            >
              {useProfilePicture ? (
                <Avatar.Image
                  size={60}
                  source={{ uri: profilePicture }}
                  onError={() => {
                    setUseProfilePicture(false);
                  }}
                />
              ) : (
                <Avatar.Text
                  size={60}
                  label={`${getInitials(user?.displayName)}`}
                  //.charAt(0)
                />
              )}
            </Pressable>

            <Text style={profileStyles.fullName} variant="bodyMedium">
              {`${user?.title} ${user?.displayName}` || "Not set"}
            </Text>
            <View style={profileStyles.action}>
              <Menu
                visible={visible}
                onDismiss={hideDialog}
                anchor={
                  <Tooltip title="More options">
                    <IconButton
                      icon={"dots-vertical"}
                      accessibilityHint="More options"
                      accessibilityLabel="More options"
                      onPress={(e) => {
                        //setVisible(true);
                        showDialog();
                        // e.preventDefault();

                        // e.stopPropagation();
                        // console.log("IS VISIBLE ", visible);
                      }}
                    ></IconButton>
                  </Tooltip>
                }
              >
                <Menu.Item
                  onPress={() => {
                    navigation.navigate("EditProfile");
                    hideDialog();
                  }}
                  title="Edit Account"
                />
                <Menu.Item
                  onPress={() => {
                    navigation.navigate("Auth", { screen: "PasswordChange" });
                    hideDialog();
                  }}
                  title="Change Password"
                />
                <Menu.Item
                  onPress={() => {
                    navigation.navigate("Auth", { screen: "PasswordReset" });
                    hideDialog();
                  }}
                  title="Forgot Password"
                />
                <Menu.Item
                  onPress={() => {
                    navigation.navigate("Auth", { screen: "EmailChange" });
                    hideDialog();
                  }}
                  title="Change Email"
                />
                <Divider />

                <Menu.Item
                  onPress={() => {
                    logout();
                    hideDialog();
                  }}
                  title="Logout"
                />
                <Menu.Item
                  onPress={() => {
                    //logout();
                    navigation.navigate("Auth", { screen: "AccountDelete" });
                    hideDialog();
                  }}
                  title="Delete account"
                />
              </Menu>
            </View>
            {/*
            Update
            Log out
            Delete account
              */}
          </View>

          <Divider />

          {/* Section 2: User Details */}
          <View style={profileStyles.section}>
            <List.Item
              title="Full name"
              description={user?.displayName || "Not set"}
              titleStyle={profileStyles.listTitle}
              left={() => <List.Icon icon="account" />}
              //right={() => <Text>{user?.displayName || "Not set"}</Text>}
            />
            <List.Item
              title="Occupation"
              description={user?.occupation || "Not set"}
              titleStyle={profileStyles.listTitle}
              left={() => <List.Icon icon="briefcase" />}
              //right={() => <Text>{user?.occupation || "Not set"}</Text>}
            />
            <List.Item
              title="State"
              titleStyle={profileStyles.listTitle}
              description={user?.state || "Not set"}
              left={() => <List.Icon icon="home-group" />}
              //right={() => <Text>{user?.state || "Not set"}</Text>}
            />
            <List.Item
              title="City"
              titleStyle={profileStyles.listTitle}
              description={user?.city || "Not set"}
              left={() => <List.Icon icon="home-city" />}
              // right={() => <Text>{user?.city || "Not set"}</Text>}
            />
            <List.Item
              title="Zip / Postal Code"
              titleStyle={profileStyles.listTitle}
              description={user?.zip || "Not set"}
              left={() => <List.Icon icon="map-marker-path" />}
              // right={() => <Text>{user?.city || "Not set"}</Text>}
            />
            <List.Item
              title="Address"
              titleStyle={profileStyles.listTitle}
              description={user?.address || "Not set"}
              left={() => <List.Icon icon="home-map-marker" />}
              //right={() => <Text>{user?.address || "Not set"}</Text>}
            />
            <List.Item
              title="Phone"
              description={user?.phoneNumber || "Not set"}
              titleStyle={profileStyles.listTitle}
              left={() => <List.Icon icon="phone" />}
              // right={() => (
              //   <Text>{user.phoneNumber ? user.phoneNumber : "Not set"}</Text>
              // )}
            />

            <List.Item
              title="Email"
              description={user?.email || "Not set"}
              titleStyle={profileStyles.listTitle}
              left={() => <List.Icon icon="email-outline" />}
              // right={() => <Text>{user.email ? user.email : "Not set"}</Text>}
              right={() => (
                <IconButton
                  accessibilityLabel="Edit email"
                  icon="email-edit-outline"
                  onPress={() =>
                    navigation.navigate("Auth", { screen: "EmailChange" })
                  }
                />
              )}
            />

            <List.Item
              title="Verification"
              titleStyle={profileStyles.listTitle}
              left={() => <List.Icon icon="shield-check-outline" />}
              right={() => (
                <>
                  {
                    user.emailVerified ? (
                      <Text>Verified</Text>
                    ) : (
                      <Button
                        onPress={() => verifyEmail()}
                        disabled={emailSent}
                      >
                        {verificationMessage}
                      </Button>
                    )
                    //!user.emailVerified && <Button onPress={sendEmailVerification}>Verify</Button>
                  }
                </>
              )}
            />
          </View>
          <Divider />
          <View style={profileStyles.section}>
            <List.Item
              title="Whypes"
              titleStyle={profileStyles.listTitle}
              left={() => <List.Icon icon="car-wash" />}
              right={() => <Text>{currentMonthWhypes()}</Text>}
            />
          </View>
          <Divider />

          {/* Section 4: Theme Toggle */}
          <View style={profileStyles.section}>
            <List.Item
              title="Theme"
              description="Select Theme"
              left={() => <List.Icon icon="theme-light-dark" />}
              right={() => (
                <ToggleButton.Group
                  onValueChange={toggleTheme}
                  value={isDark ? "dark" : "light"}
                >
                  <ToggleButton
                    icon="white-balance-sunny"
                    value="light"
                    style={isDark ? null : { backgroundColor: SECONDARY_COLOR }}
                  />
                  <ToggleButton
                    icon="weather-night"
                    value="dark"
                    style={isDark ? { backgroundColor: SECONDARY_COLOR } : null}
                  />
                </ToggleButton.Group>
              )}
            />

            {/* <List.Item
              title="Sign in options"
              description="Enable fast sign in with your phone authentication"
              left={() => <List.Icon icon="theme-light-dark" />}
              right={() => (
                <ToggleButton.Group
                  onValueChange={toggleTheme}
                  value={isDark ? "dark" : "light"}
                >
                  <ToggleButton
                    icon="cancel"
                    value="default"
                    style={isDark ? null : { backgroundColor: SECONDARY_COLOR }}
                  />
                  <ToggleButton
                    icon="lock"
                    value="dark"
                    style={isDark ? { backgroundColor: SECONDARY_COLOR } : null}
                  />
                </ToggleButton.Group>
              )}
            /> */}
          </View>

          {/* <Divider />
          <LocationPermissionsButton />
          <Divider /> */}

          {/* Section 5: Links */}
          {/* Webview */}
          <View style={profileStyles.section}>
            <View style={profileStyles.section}>
              {links?.map((link, index) => (
                <List.Item
                  key={index}
                  title={link.title}
                  left={() => <List.Icon icon="link" />}
                  onPress={() => webResource(link?.url)}
                />
              ))}
              <List.Item
                key="share"
                title="Share"
                left={() => <List.Icon icon="share-variant" />}
                onPress={onShare}
              />
            </View>

            {/* WebView modal */}
            {/*
            <Modal animationType="slide" visible={webViewUrl !== null}>
              <View style={{ flex: 1 }}>
                <WebView source={{ uri: webViewUrl }} />
                <IconButton
                  icon="close"
                  size={30}
                  style={{ position: "absolute", top: 10, right: 10 }}
                  onPress={() => setWebViewUrl(null)}
                />
              </View>
            </Modal>
            */}
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default ProfileScreen;
