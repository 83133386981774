import React, { Component, useContext, useState, useEffect } from "react";

import { Card, useTheme as paperTheme } from "react-native-paper";
import { View, Text } from "react-native";
import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  styles,
  whypeStyles,
} from "../utils/styles/main";
import { useAuth } from "../utils/AuthContext";

const HomeInfoBoard = () => {
  const { user, whypes, currentMonthWhypes } = useAuth();

  return (
    <Card
      style={[
        styles.card,
        {
          marginVertical: 20,
          backgroundColor: PRIMARY_COLOR,
          color: "white",
        },
      ]}
    >
      <Card.Title
        titleVariant="titleMedium"
        title={
          "Welcome, " +
          `${user?.title || ""} ${user?.fullName || user?.displayName}`
        }
        titleStyle={[styles.cardTitle, styles.card.text]}
      />
      <Card.Content
        style={{ flexDirection: "row", justifyContent: "space-between" }}
      >
        <View>
          <Text variant="bodySmall" style={styles.card.text}>
            Whypes this month:
          </Text>
          <Text variant="bodySmall" style={styles.card.text}>
            Total whypes:
          </Text>
          <Text variant="bodySmall" style={styles.card.text}>
            Current city:
          </Text>
        </View>
        <View>
          <Text
            variant="bodySmall"
            style={[styles.card.text, styles.textRight]}
          >
            {currentMonthWhypes()}
          </Text>
          <Text
            variant="bodySmall"
            style={[styles.card.text, styles.textRight]}
          >
            {whypes.length}
          </Text>
          <Text
            variant="bodySmall"
            style={[styles.card.text, styles.textRight]}
          >
            {user?.city}
          </Text>
        </View>
      </Card.Content>
    </Card>
  );
};

export default HomeInfoBoard;
