import React, { useState } from "react";
import { View, ScrollView, Dimensions, Platform } from "react-native";
import { Text, Button, TextInput, Checkbox } from "react-native-paper";
import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  styles,
} from "../../utils/styles/main";
import { SafeAreaView } from "react-native-safe-area-context";

import { useAuth } from "../../utils/AuthContext";
import ActionFeedback from "../../components/ActionFeedback";
import Resources from "../../utils/Resources";
import BreathingImage from "../../components/BreathingImage";

const SignIn = ({ navigation }) => {
  const [email, setEmail] = useState("");
  const [isEmailError, setEmailError] = useState(false);
  const [pinSign, setPin] = useState("");
  //const [isLoading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  //const { user, setUser } = useAuth();
  const { login, loginWithPin, isLoading } = useAuth();
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  //const [margin, setMargin] = useState(20);

  const handleSignIn = async () => {
    // handle sign in logic here
    if (email.length > 5 && password.length > 5) {
      // check if email has '@' in it or if it's completely numeric
      // SO we can tell if it's email or phone, ten we pass isEmail to login
      await login(email, password, rememberMe);
      //if (rememberMe) setPersistence(auth, browserLocalPersistence);
    } else {
      return;
    }
  };

  // const handleDimensionsChange = (width) => {
  //   //const width = Dimensions.get("window").width;
  //   if (Platform.OS === "web" && width > 446) {
  //     // Calculate the margin percentage between 20% and 40%
  //     const newMargin = 18 + ((width - 446) / (width - 446 + 40)) * 20;
  //     setMargin(`${Math.min(newMargin, 40)}%`); // Ensure the maximum margin is 40%
  //   } else {
  //     setMargin(20);
  //   }
  // };
  return (
    <SafeAreaView
      style={{ flex: 1, justifyContent: "center" }}
      // onLayout={({ nativeEvent }) =>
      //   handleDimensionsChange(nativeEvent.layout.width)
      // }
    >
      <ActionFeedback />
      {/* <ScrollView> */}

      <View style={{ justifyContent: "center" }}>
        <ScrollView containerContentStyle={{ flex: 1 }}>
          <BreathingImage
            style={styles.loginImage}
            source={require("../../assets/images/ICON_.png")}
          />
          {/* <View style={[styles.container, { paddingHorizontal: margin }]}> */}
          <View style={styles.container}>
            <Text style={[styles.signInHeading, styles.text.primary]}>
              Welcome back
            </Text>

            <TextInput
              label="Email / Phone Number"
              value={email}
              onChangeText={(text) => setEmail(text.trim())}
              mode="outlined"
              style={styles.input}
            />
            <TextInput
              label="Password"
              value={password}
              onChangeText={(text) => setPassword(text.trim())}
              mode="outlined"
              secureTextEntry={showPassword}
              left={<TextInput.Icon icon="form-textbox-password" />}
              right={
                <TextInput.Icon
                  onPress={() => setShowPassword(!showPassword)}
                  icon={showPassword ? "eye-off" : "eye"}
                />
              }
              style={styles.input}
            />
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Button
                onPress={() => navigation.navigate("PasswordReset")}
                textColor={styles.text.primary.color}
              >
                Forgot password?
              </Button>
              <Checkbox.Item
                accessibilityLabel="Remember me"
                label="Remember me"
                labelStyle={styles.text.primary}
                status={rememberMe ? "checked" : "unchecked"}
                color={PRIMARY_COLOR}
                onPress={() => setRememberMe(!rememberMe)}
              />
            </View>
            <View style={styles.btnContainer}>
              <Button
                mode="contained"
                onPress={handleSignIn}
                style={styles.signInButton}
                loading={isLoading}
                disabled={isLoading}
                icon="login-variant"
                contentStyle={{ flexDirection: "row-reverse" }}
                textColor="white"
              >
                Sign In
              </Button>

              <Button
                onPress={() => navigation.navigate("SignUp")}
                labelStyle={styles.text.primary}
              >
                Create an Account
              </Button>
            </View>
          </View>
          <Resources />
        </ScrollView>
      </View>
      {/* </ScrollView> */}
    </SafeAreaView>
  );
};


export default SignIn;
