import React, { createContext, useState, useEffect } from "react";
//import jwtDecode from "jwt-decode";
//import * as SecureStore from "expo-secure-store";
import { setItem, getItem, removeItem } from "./storage/Storage";
import { getCurrentMonth } from "./DateTIme";
import { Platform } from "react-native";
import axios from "axios";

//import NetInfo from "@react-native-community/netinfo";
//import { client, mainApi } from "../api/main";

/*
  This is a context that will be used to store the user's information
  and to login, register, and logout the user.

NOTE: Expo SecureStore does not currently support web, so this will not work in the browser.
So we will need to use a different method to store the token in the browser.
*/

// Implement network watcher to know when user
// has internet connection and or data is turned off
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  //const [userToken, setToken] = useState(null);
  //const [refreshToken, setRefreshToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  //const [ authError, setAuthError ] = useState(false);
  const [message, setMessage] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState();
  const [whypes, setWhypes] = useState([]);
  const [tips, setTips] = useState();
  const [updatesAndPromo, setUpdatesAndPromo] = useState([]);
  const [App, setApp] = useState();
  const [recentActivity, setRecentActivity] = useState(null);

  useEffect(() => {
    // Check for user on app load
    const bootstrapAsync = async () => {
      try {
        const storedUser = await getItem("user");
        //const refreshToken = await getItem("refreshToken");
        //const token = await getItem("token");

        // TODO: Check the token on firebase if it matches the local token
        // If it matches, then proceed. Else, use have to login again.
        // If network error or user is offline, prompt to turn on data connection.
        // Should only allow when user auth is confirmed and valid.
        if (storedUser) {
          // Check if user has email, if not log out user
          const parsedUser = JSON.parse(storedUser);
          setUser(parsedUser);
          //setRefreshToken(parsedUser.refreshToken);
          //setToken(parsedUser.accessToken);
        }

        setIsLoading(false);
        //setAuthError(false);
        setMessage(null);
      } catch (e) {
        //setAuthError(true);
        setMessage(e.message);
        setIsLoading(false);
      }
      //await loadTips();
    };
    bootstrapAsync();
  }, []);

  const API_URL = "https://api.whypemasters.ng";

  const client = axios.create({
    baseURL: `${API_URL}/auth`,
    headers: {
      "Content-Type": "application/json",
      "Is-Client": "true",
      "Refresh-Token": `${user?.refreshToken || ""}`,
      "Access-Token": `${user?.accessToken || ""}`,
      UID: `${user?.userUid}`,
      Authorization: `Bearer ${user?.accessToken}`,
    },
    withCredentials: true,
  });

  const mainApi = axios.create({
    baseURL: API_URL,
    headers: {
      "Content-Type": "application/json",
      "Is-Client": "true",
      "Access-Token": `${user?.accessToken}`,
      //"Refresh-Token": `${refreshToken}`,
      UID: `${user?.userUid}`,
      Authorization: `Bearer ${user?.accessToken}`,
    },
    withCredentials: true,
  });

  const updateProfile = async (updates) => {
    setIsLoading(true);
    try {
      const response = await client.post(`/users/update/`, updates);
      const { data } = response;
      if (data.updated) {
        await updateLocalUserInfo(updates);
        setMessage(data?.message);

        return true;
      } else {
        setMessage(
          error?.response?.data?.message ||
            "Error updating profile. Please and try again."
        );
        return false;
      }
    } catch (error) {
      setMessage(
        error?.response?.data?.message ||
          "Error updating profile. Please and try again."
      );
      return false;
      //setAuthError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const updateLocalUserInfo = async (updates) => {
    try {
      const storedUserData = await getItem("user");
      if (storedUserData) {
        const localUser = JSON.parse(storedUserData);
        const updatedUser = { ...localUser, ...updates };

        const updatedUserData = JSON.stringify(updatedUser);
        await setItem("user", updatedUserData);
        //setUser(updatedUser);
      } else {
        setItem("user", JSON.stringify(updates));
      }
    } catch (error) {
      //if (response.status === 400) checkOrRefreshUserAuth();

      return null;
    }
    //setUser(user);
    //setRefreshToken(user.refreshToken);
    //setToken(user.accessToken);
    //return user;
  };

  const refreshUser = async () => {
    setIsLoading(true);
    // Run in the background after 40 minutes
    try {
      const { data } = await client.get(`/refresh/?action=user`);
      if (data.refreshed) {
        setMessage(null);
        const { user } = data;
        await updateLocalUserInfo(user);
        setUser(user);
      } else {
        // Log user out
        await logout();
      }
    } catch (error) {
      setMessage(error?.message || error?.response.statusText);
    } finally {
      setIsLoading(false);
    }
  };

  const checkOrRefreshUserAuth = async (callback) => {
    // Run in the background after 40 minutes
    try {
      const { data } = await client.get(`/refresh/?action=token`);
      if (data.refreshed) {
        const { tokens } = data;
        //setMessage(null);
        const updates = {
          userUid: tokens.userId,
          //accessToken: tokens.accessToken,
          accessToken: tokens.idToken,
          refreshToken: tokens.refreshToken,
        };
        await updateLocalUserInfo(updates);
        //if (callback) callback();
        //setMessage(data?.message || "An error occured, please try agaain.");
      } else {
        // Log user out
        await logout();
      }
    } catch (error) {
      //setAuthError(true);
      setMessage(error?.response?.statusText);
    } finally {
      setIsLoading(false);
    }
  };

  const appSetup = async () => {
    try {
      const response = await mainApi.get("/setup/");
      if (response) {
        const { data } = response;
        setApp(data);
      }
    } catch (error) {
      //setAuthError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const loadTips = async () => {
    // Load tips from server and store locally
    // Update shown tip daily (24 hours)
    // Make a get request ti check if tips has been updated
    // Perhaps by comparing the length or  modified
    try {
      const response = await mainApi.get("/resources/?action=load-tips");
      if (response) {
        const { data } = response;
        setTips(data);
      }
    } catch (error) {
      //setAuthError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const loadWhypesCategories = async () => {
    setIsLoading(true);
    let response;

    try {
      response = await mainApi.get("/whypes/categories/");
      const { data } = response;
      //setWhypes(data);
      //setItem("Categories", JSON.stringify(data));
      return data;
    } catch (error) {
      if (error?.response?.status === 403)
        checkOrRefreshUserAuth(loadWhypesCategories);

      setIsLoading(false);
      if (error?.code === "ERR_NETWORK")
        setMessage(`${error?.message}, please check internet connection.`);
      else setMessage("Unable to load plans whypes. Please try again later.");
      // Load from local storage
      //const localWhypes = await JSON.parse(getItem("whypes"));
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const loadWhypes = async () => {
    setIsLoading(true);

    try {
      const { data } = await mainApi.get("/whypes/");
      setWhypes(data);
      try {
        setItem("whypes", JSON.stringify(data));
      } catch (err) {}
    } catch (error) {
      if (error?.response?.status === 403) checkOrRefreshUserAuth(loadWhypes);

      setIsLoading(false);
      if (error?.code === "ERR_NETWORK")
        setMessage(`${error?.message}, please check internet connection.`);
      else setMessage("Unable to refresh whypes. Please try again later.");
      try {
        const localWhypes = await JSON.parse(getItem("whypes"));
        if (localWhypes) setWhypes(localWhypes);
      } catch (er) {}
    } finally {
      setIsLoading(false);
    }
  };

  const loadWhypesHistory = async (id) => {
    let history;
    setIsLoading(true);
    let response;
    try {
      response = await mainApi.get(`/whypes/${id}/history/`);
      const { data } = response;
      setItem("whypes_history", JSON.stringify(data));
      history = data;
    } catch (error) {
      if (response.status === 403) checkOrRefreshUserAuth();

      const localWhypeHistory = await getItem("whypes_history");
      if (error?.code === "ERR_NETWORK")
        setMessage(`${error?.message}, please check internet connection.`);
      else
        setMessage(
          error?.response.statusText ||
            "Unable to refresh whypes history. Please try again later."
        );
      if (localWhypeHistory) history = JSON.parse(localWhypeHistory);
    } finally {
      setIsLoading(false);
      return history;
    }
  };

  const sendWhypeFeedback = async (id, historyId, feedback) => {
    try {
      const response = await mainApi.post(
        `/whypes/${id}/history/${historyId}feedback/`,
        {
          feedback: feedback,
        }
      );
      const { data } = response;
      //   isSuccessfull = data.message;
      setMessage(data?.message);
    } catch (error) {
      if (error?.code === "ERR_NETWORK")
        setMessage(`${error?.message}, please check internet connection.`);
      else
        setMessage(error?.response?.message || "Feedback has been submitted.");
      if (error?.response?.status === 403) checkOrRefreshUserAuth();
    } finally {
      setIsLoading(false);
      //return isSuccessfull;
    }
  };

  const login = async (email, password, remember) => {
    setIsLoading(true);
    try {
      const response = await client.post(
        `/signin/`,
        // JSON.stringify({
        //   email: email,
        //   password: password,
        // })
        {
          email,
          password,
        }
      );
      const { user } = response?.data;
      const { accessToken, refreshToken } = user;
      await setItem("refreshToken", JSON.stringify(refreshToken));
      await setItem("token", JSON.stringify(accessToken));
      //if (remember) await setItem("user", JSON.stringify(user));
      if (remember) await updateLocalUserInfo(user);

      //setRefreshToken(refreshToken);
      //setToken(accessToken);
      setUser(user);
      setMessage(null);
    } catch (error) {
      if (error?.code === "ERR_NETWORK")
        setMessage(`${error?.message}, please check internet connection.`);
      else
        setMessage(
          error?.response?.statusText ||
            "An unknown error has occured. Please try again."
        );
      //setMessage(error?.response.statusText);
      //setAuthError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const register = async (data) => {
    setIsLoading(true);

    try {
      const response = await client.post(
        `/signup/`,
        data
        // JSON.stringify({
        //   title: data.title,
        //   fullName: data.fullName,
        //   occupation: data.occupation,
        //   state: data.state,
        //   city: data.city,
        //   address: data.address,
        //   phone: data.phone,
        //   email: data.email,
        //   password: data.password,
        // })
      );

      //const { emailVerified, registered } = response?.data;
      if (response?.data?.registered) {
        setMessage(
          "Registration is succesful! Now, please check your email for verification."
        );
      } else {
        setMessage("Registrations failed. Let's give it another shot!");
      }
      //return emailVerified;
    } catch (error) {
      if (error?.code === "ERR_NETWORK")
        setMessage(`${error?.message}, please check internet connection.`);
      else
        setMessage(
          error?.response?.statusText ||
            "An unknown error has occured. Please try again."
        );
      //setAuthError(true);
      //setMessage(error?.response.statusText);
      setMessage("");
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    setIsLoading(true);
    try {
      const response = await client.post(`/signout/`, {});
      if (response?.data?.success) {
        await Promise.all([
          removeItem("token"),
          removeItem("user"),
          removeItem("refreshToken"),
          removeItem("whypes"),
          removeItem("whypes_history"),
          removeItem("isDark"),
          //removeItem("notifications"),
        ]);
        setUser(null);
        //setToken(null);
        setMessage("Please sign in again");
      } else {
        setMessage("An error occured, please try again.");
      }
    } catch (error) {
      setMessage("Sorry, somethig went wrong. Please try again.");
      //setAuthError(true);
      //setMessage(error?.response?.statusText);
    } finally {
      setIsLoading(false);
    }
  };

  const sendEmailVerification = async () => {
    // Set user email verified to true
    // How to update saved user object
    try {
      const response = await client.get(
        `/action/?mode=sendVerifyEmail&uid=${user.userUid}`
      );
      if (response) {
        const { takeAction } = response.data;
        //if (message === "EXPIRED_REFRESH_TOKEN") {
        if (takeAction === "REFRESH") {
          await checkOrRefreshUserAuth();
        }

        setMessage(null);
      }
    } catch (error) {
      //setAuthError(true);
      if (error.status === 401) checkOrRefreshUserAuth();
      if (error?.response?.data?.message) {
        setMessage(error?.response?.data?.message);
      } else {
        if (error?.code === "ERR_NETWORK")
          setMessage(`${error?.message}, please check internet connection.`);
        else
          setMessage(
            error?.response?.statusText ||
              "An unknown error has occured. Please try again."
          );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const changeEmail = async (newEmail, password) => {
    try {
      const response = await client.post(
        `/action/?mode=sendVerifyEmail&uid=${user.userUid}`
      );

      const { message } = response.data;
      setMessage(message);
      return { message, error: false };
    } catch (error) {
      if (error?.response?.data?.message) {
        setMessage(error?.response?.data?.message);
      } else {
        if (error?.code === "ERR_NETWORK")
          setMessage(`${error?.message}, please check internet connection.`);
        else
          setMessage(
            error?.response?.statusText ||
              "An unknown error has occured. Please try again."
          );
      }
      return { message: false, error: error?.response.statusText };
    } finally {
      setIsLoading(false);
    }
  };

  const accountDelete = async ({ password, reason }) => {
    setIsLoading(true);
    try {
      const { data } = await client.post("/account/delete/", {
        password,
        reason,
      });
      if (data?.success) {
        setMessage(data?.message);
        await logout();
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        setMessage(error?.response?.data?.message);
      } else {
        if (error?.code === "ERR_NETWORK")
          setMessage(`${error?.message}, please check internet connection.`);
        else
          setMessage(
            error?.response?.statusText ||
              "An unknown error has occured. Please try again."
          );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchStates = async () => {
    setIsLoading(true);
    let states;
    try {
      const { data } = await mainApi.get("/resources/form/?resource=states");
      states = data;
    } catch (error) {
      if (error?.response?.status === 403) checkOrRefreshUserAuth(fetchStates);

      if (error?.response?.data?.message) {
        setMessage(error?.response?.data?.message);
      } else {
        if (error?.code === "ERR_NETWORK")
          setMessage(`${error?.message}, please check internet connection.`);
        else
          setMessage(
            error?.response?.statusText ||
              "Unable to load states, please reload."
          );
      }
    } finally {
      setIsLoading(false);
      return { data: states };
    }
  };

  const changePassword = async (currentPassword, newPassword) => {
    try {
      const response = await client.post(`/password/change/`, {
        currentPassword: currentPassword,
        newPassword: newPassword,
      });

      const { message } = response.data;
      setMessage(message);
      return { message, error: false };
    } catch (error) {
      if (error?.response?.data?.message) {
        setMessage(error?.response?.data?.message);
      } else {
        if (error?.code === "ERR_NETWORK")
          setMessage(`${error?.message}, please check internet connection.`);
        else
          setMessage(
            error?.response?.statusText ||
              "An unknown error has occured, please try again."
          );
      }
      return { message: false, error: error?.response.statusText };
    } finally {
      setIsLoading(false);
    }
  };

  const getUpdates = async () => {
    let data;
    try {
      const response = mainApi.get("/updates-promo/");
      data = response?.data;
    } catch (error) {
    } finally {
      if (data) setUpdatesAndPromo(data);
    }
  };

  const currentMonthWhypes = () => {
    const currentMonth = new Date().toLocaleString("default", {
      month: "long",
    });

    const thisMonthWhypes = whypes.filter((whype) => {
      // const whypeMonth = new Date(whype.created_at).getMonth() + 1;
      const whypeMonth = getCurrentMonth(whype.created_at);
      return whypeMonth === currentMonth;
    });
    return thisMonthWhypes.length;
  };

  const renameWhype = async ({ id, newName }) => {
    setIsLoading(true);

    try {
      const { data } = await mainApi.post(`/whypes/${id}/`, {
        id: id,
        name: newName,
      });
      //return data;
      if (data.updated) {
        setMessage("Changes saved successfully");
        return true;
      }
    } catch (error) {
      if (error?.response?.status === 403) checkOrRefreshUserAuth(renameWhype);
      setIsLoading(false);
      if (error?.response?.data?.message) {
        setMessage(error?.response?.data?.message);
      } else {
        if (error?.code === "ERR_NETWORK")
          setMessage(`${error?.message}, please check internet connection.`);
        else
          setMessage(
            error?.response?.statusText ||
              "Could not save changes, please try again."
          );
      }
      // Load from local storage
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const loadWhypePackages = async () => {
    setIsLoading(true);

    try {
      const { data } = await mainApi.get("/whypes/packages/");
      return data;
    } catch (error) {
      if (error?.response?.status === 403)
        checkOrRefreshUserAuth(loadWhypesPackages);

      setIsLoading(false);
      if (error?.code === "ERR_NETWORK")
        setMessage(`${error?.message}, please check internet connection.`);
      else
        setMessage(
          error?.response?.statusText ||
            "Unable to load packages. Please try again later."
        );
      // Load from local storage
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const loadAdditionalServices = async () => {
    setIsLoading(true);

    try {
      const { data } = await mainApi.get("/whypes/packages/?load=addons");
      return data;
    } catch (error) {
      if (error?.response?.status === 403)
        checkOrRefreshUserAuth(loadAdditionalServices);
      setIsLoading(false);
      if (error?.code === "ERR_NETWORK")
        setMessage(`${error?.message}, please check internet connection.`);
      else
        setMessage(
          error?.response?.statusText ||
            "Unable to load additional services. Please try again later."
        );
      // Load from local storage
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const handlePurchase = async (items) => {
    setIsLoading(true);

    try {
      const response = await mainApi.post(
        `/whypes/packages/create/?purchase-type=${items.purchaseType}`,
        {
          items,
        }
      );
      const { data } = response;
      setMessage(data?.message);
      return data;
    } catch (error) {
      setMessage(`Error - ${error?.response?.statusText}`);
      if (error?.code === "ERR_NETWORK")
        setMessage(`${error?.message}, please check internet connection.`);
      else
        setMessage(
          error?.response?.statusText ||
            "An error occured while sending your purchase information. Please try again."
        );
      return {
        success: false,
        message: `Error - ${error?.response?.statusText}`,
      };
    } finally {
      setIsLoading(false);
    }
  };

  const scheduleWhype = async ({
    whypeId,
    newSchedule,
    deleteSchedule,
    deleteAll,
    renameWhype,
  }) => {
    // If number of schedule is greater than remaining (Available)
    // whype, show error "You can not schedule more than ${remaining whype}
    setIsLoading(true);
    let success = false;
    try {
      const { data } = await mainApi.post(`/whypes/${whypeId}/schedules/`, {
        newSchedule,
        deleteSchedule,
        deleteAll,
        renameWhype,
      });
      //return data;
      if (data.success) {
        //setMessage("Changes saved successfully");
        setMessage(data.message);
        success = data?.success;
      }
    } catch (error) {
      if (error?.response?.status === 403)
        checkOrRefreshUserAuth(scheduleWhype);
      setIsLoading(false);
      setMessage(
        error?.response?.data?.message ||
          "Could not save changes. Please try again later."
      );
    } finally {
      setIsLoading(false);
      return { success };
    }
  };

  const getSchedules = async ({ whypeId }) => {
    setIsLoading(true);

    try {
      const { data } = await mainApi.get(`/whypes/${whypeId}/schedules/`);
      return { success: true, remoteSchedules: data };
    } catch (error) {
      if (error?.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        if (error?.response?.data?.message) {
          setMessage(error?.response?.data?.message);
        } else {
          if (error?.code === "ERR_NETWORK")
            setMessage(`${error?.message}, please check internet connection.`);
          else
            setMessage(
              error?.response?.statusText ||
                "Could not save changes, please try again."
            );
        }

        if (error?.response?.status === 403) {
          checkOrRefreshUserAuth();
        } else {
          setMessage("Unknown server error");
        }
        return { success: false, message: error.response.statusText };
      }
    } finally {
      setIsLoading(false);
    }
  };

  const verifyCheckpointCode = async ({ verificationCode }) => {
    // Verify code from whyper
    try {
      const { data } = await mainApi.post("/whypes/checkpoint/", {
        verificationCode: verificationCode,
      });
      return data;
    } catch (error) {
      if (error?.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        if (error?.response?.data?.message) {
          setMessage(error?.response?.data?.message);
        } else {
          if (error?.code === "ERR_NETWORK")
            setMessage(`${error?.message}, please check internet connection.`);
          else
            setMessage(
              error?.response?.statusText ||
                "An error occured while verifying code. Please try again."
            );
        }

        return { success: false, message: message };
      }

      return { message: errorMessage };
    } finally {
      setIsLoading(false);
    }
  };

  const deleteNotification = async ({ notificationId }) => {
    setIsLoading(true);
    try {
      const { data } = await mainApi.post("/notifications/", {
        notificationId: notificationId,
      });
      if (data?.success) {
        setMessage(data?.message);
      }
    } catch (error) {
      setMessage(error?.response?.data?.message);
    }
  };

  const markNotificationAsRead = async ({ notificationId }) => {
    setIsLoading(true);
    try {
      const { data } = await mainApi.post("/notifications/?read=true", {
        notificationId: notificationId,
      });
      if (data?.success) {
        setMessage(data?.message);
      }
    } catch (error) {
      setMessage(error?.response?.data?.message);
    }
  };

  const fetchNotification = async () => {
    let notifications;
    try {
      const { data } = await mainApi.get("/notifications/");
      notifications = data?.notifications;
    } catch (error) {
      // if (error?.response?.status === 403) checkOrRefreshUserAuth();
      // else {
      // }
    } finally {
      if (notifications) {
        setNotifications(notifications);
        setNotificationCount(notifications?.length);
        setRecentActivity(notifications[0]);
      }
    }
  };

  React.useEffect(() => {
    const getNotifications = async () => {
      if (user) fetchNotification();
      // if (notifications) {
      //   setNotifications(notifications);
      // }
    };
    getNotifications();
  }, [message]);

  const bootstrap = async () => {
    setIsLoading(true);
    if (Platform.OS == "web") {
      var script = document.createElement("script");
      script.src = "https://js.paystack.co/v1/inline.js";
      document.head.appendChild(script);
    }

    try {
      await getUpdates();
      await appSetup();
      await loadWhypes();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        //authError,
        //setAuthError,
        setMessage,
        message,
        user,
        setUser,
        refreshUser,
        //setToken,
        //userToken,
        register,
        sendEmailVerification,
        updateProfile,
        login,
        logout,
        accountDelete,
        isLoading,
        loadWhypes,
        loadWhypesHistory,
        whypes,
        scheduleWhype,
        getSchedules,
        handlePurchase,
        renameWhype,
        tips,
        loadTips,
        sendWhypeFeedback,
        loadWhypePackages,
        currentMonthWhypes,
        loadWhypesCategories,
        loadAdditionalServices,
        bootstrap,
        //recentActivities,
        updatesAndPromo,
        changeEmail,
        changePassword,
        verifyCheckpointCode,
        fetchStates,
        deleteNotification,
        notificationCount,
        notifications,
        recentActivity,
        setIsLoading,
        App,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
