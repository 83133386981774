import React, { useState, useEffect } from "react";

import { View, Text } from "react-native";
import { useAuth } from "../utils/AuthContext";
import {
  styles,
} from "../utils/styles/main";

const Tips = ({ style }) => {
  const { tips, loadTips } = useAuth();

  useEffect(() => {
    const getTips = async () => {
      await loadTips();
    };
    getTips();
  }, []);

  return (
    <View style={styles.dailyTip}>
      <Text style={[styles.dailyTipHeader, styles.text.primary]}>
        Daily Maintenance Tip:
      </Text>
      <View>
        <Text
          style={[styles.dailyTipText, styles.text.secondary]}
          variant="bodySmall"
        >
          {tips?.tip}
        </Text>
      </View>
    </View>
  );
};

export default Tips;
