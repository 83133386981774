import React from "react";
import { View, StyleSheet } from "react-native";
import { Badge, Divider, Icon, Text } from "react-native-paper";
import { styles, PRIMARY_COLOR, SECONDARY_COLOR } from "../utils/styles/main";



const StepWrapper = ({}) => {
  return {};
};

export const StepIndicator = ({ totalSteps, completedSteps }) => {
  return (
    //   <View style={styles.container}>
    //     {steps.map((step, index) => (
    //       <View key={step} style={styles.stepContainer}>
    //         {index !== 0 && <View style={styles.connector} />}
    //         {index < completedSteps ? (
    //           <Badge style={styles.completedStep}>
    //             <IconButton icon="check" color="white" size={16} />
    //           </Badge>
    //         ) : (
    //           <Badge style={styles.remainingStep}>
    //             <Text style={styles.remainingStepText}>{step}</Text>
    //           </Badge>
    //         )}
    //       </View>
    //     ))}
    //   </View>
    // );

    <View style={indicatorStyles.stepIndicator}>
      {[...Array(totalSteps)].map((_, index) => (
        <>
          <Badge
            style={
              index < completedSteps
                ? indicatorStyles.completedStep
                : indicatorStyles.remainingStep
            }
            key={`badge-${index}`}
          >
            {index < completedSteps ? (
              <Icon source="check" color="white" />
            ) : (
              <Text style={indicatorStyles.remainingStepText}>{index + 1}</Text>
            )}
          </Badge>
          {index < totalSteps - 1 && (
            <View
              style={[
                indicatorStyles.connector,
                index < completedSteps
                  ? indicatorStyles.prevStep
                  : indicatorStyles.nextStep,
              ]}
              key={`connector-${index}`}
            ></View>
          )}
        </>
      ))}
    </View>
  );
};

const BadgeComponent = ({ size, color, content }) => {
  return (
    <View>
      {/*<Icon name="bell" size={size} color={color} /> */}
      <Badge size={size}>{content}</Badge>
    </View>
  );
};

export const UiSteps = ({ steps, currentStep }) => {
  <View style={styles.row}>
    {steps.includes(currentStep) ? (
      <Icon source="check" size={25} color={styles.white} />
    ) : (
      <Text style={styles.white}>{currentStep}</Text>
    )}
  </View>;
};

const indicatorStyles = StyleSheet.create({
  stepIndicator: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
    paddingHorizontal: 10,
  },
  completedStep: {
    backgroundColor: PRIMARY_COLOR,
  },
  remainingStep: {
    backgroundColor: "transparent",
    borderWidth: 1,
    borderColor: "grey",
  },
  remainingStepText: {
    color: "grey",
  },
  connector: {
    flex: 1,
    borderBottomWidth: 2,
    height: 2,
  },
  nextStep: {
    borderColor: "grey",
  },
  prevStep: {
    borderColor: PRIMARY_COLOR,
  },
});

export default BadgeComponent;
