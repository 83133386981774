import React from "react";
import { View, Image, ScrollView, RefreshControl } from "react-native";
import { styles } from "../utils/styles/main";

import Tips from "../components/DailyTips";
import { SafeAreaView } from "react-native-safe-area-context";
//import { BadgeContext } from '../components/BadgeContext';
import ActionFeedback from "../components/ActionFeedback";
//import Section from '../../components/Section';
import HomeInfoBoard from "../components/HomeInfoBoard";
import ActivitiesComponent from "../components/RecentActivities";
import HomeCallToAcion from "../components/HomeCallToAction";
import WhypersCheckpoint from "../components/WhyperCheckpoint";
import UpdatesAndPromo from "../components/UpdatesAndPromo";

import { useAuth } from "../utils/AuthContext";

const HomeScreen = ({ navigation }) => {
  const { bootstrap, isLoading } = useAuth();
  React.useEffect(() => {
    async function bootstrapAsync() {
      await bootstrap();
    }
    bootstrapAsync();
  }, []);
  return (
    <>
      <ActionFeedback />

      <ScrollView
        refreshControl={
          <RefreshControl refreshing={isLoading} onRefresh={bootstrap} />
        }
      >
        <View style={styles.container}>
          <HomeInfoBoard />
          <UpdatesAndPromo />
          <Tips />
          <HomeCallToAcion navigation={navigation} />
          <WhypersCheckpoint />
          <ActivitiesComponent navigation={navigation} />
        </View>
      </ScrollView>
    </>
  );
};

export default HomeScreen;
