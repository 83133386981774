import React, { useState, useRef } from "react";
import {
  View,
  ScrollView,
  RefreshControl,
  Alert,
  FlatList,
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Text, Button, Appbar, Card, Icon } from "react-native-paper";

import {
  SECONDARY_COLOR,
  PRIMARY_COLOR,
  styles,
  whypeStyles,
} from "../utils/styles/main";
import { useAuth } from "../utils/AuthContext";
import ActionFeedback from "../components/ActionFeedback";
import { SelectionComponent } from "../components/SelectionComponents";
import { StepIndicator } from "../components/BadgeComponent";

const WhypePlanScreen = ({
  //isLoading,
  //loadWhypesCategories,
  navigation,
}) => {
  const colors = [PRIMARY_COLOR, SECONDARY_COLOR];
  const colorIndexRef = useRef(0);
  // const [selectedCategory, setSelectedCategory] = useState(data.categories[0]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  //const [selectedCard, setSelectedCard] = useState(null);
  //const [selectedPlans, setSelectedPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState();

  const [total, setTotal] = useState();
  //console.log("USEAUTH ", useAuth());
  const { loadWhypesCategories, isLoading } = useAuth();
  const refreshCategories = async () => {
    try {
      const loadedCategories = await loadWhypesCategories();
      if (loadedCategories && loadedCategories.length > 0) {
        setCategories(loadedCategories);
        setSelectedCategory(loadedCategories[0]);
      } else {
        setCategories([]);
        setSelectedCategory(null);
      }
    } catch (error) {
      //console.error(error);
    }
  };

  // Load the initial data when the screen loads
  React.useEffect(() => {
    refreshCategories();
  }, []);

  const handlePlanSelect = (plan) => {
    const isSelected = selectedPlan?.name == plan.name;

    if (isSelected) {
      setSelectedPlan(null);
      setTotal();
      return;
    }
    setSelectedPlan(plan);
    setTotal(plan.price);
  };

  const totalSteps = 2;
  const completedSteps = 0;

  const handleSubmit = () => {
    if (!selectedPlan) {
      Alert.alert("Please select at least one plan.");
      return;
    }
    navigation.navigate("PaymentPortal", {
      total,
      selectedPlan: {
        id: selectedPlan.id,
        name: selectedPlan.name,
        price: selectedPlan.price,
        category: {
          id: selectedCategory?.id,
          name: selectedCategory?.name,
        },
      },
      totalSteps,
      completedSteps,
      purchaseType: "plan",
    });
  };

  return (
    <SafeAreaView style={styles.safeArea}>
      <ActionFeedback />
      <Appbar.Header statusBarHeight={0}>
        <Appbar.BackAction
          onPress={() => {
            navigation.navigate("WhypesList");
          }}
          accessibilityLabel="Go back"
        />

        <Appbar.Content
          title="Create whype - Plan"
          titleStyle={{ fontSize: 14 }}
        />
      </Appbar.Header>
      {categories.length > 0 && (
        <StepIndicator
          totalSteps={totalSteps}
          completedSteps={completedSteps}
        />
      )}
      {categories.length > 0 && (
        <ScrollView
          refreshControl={
            <RefreshControl
              refreshing={isLoading}
              onRefresh={refreshCategories}
            />
          }
          contentContainerStyle={styles.container}
        >
          {/* Render the plans associated with the selected category */}
          {selectedCategory?.plans.length > 0 && (
            <ScrollView>
              {selectedCategory?.plans.map((plan, index) => (
                <SelectionComponent
                  key={index}
                  plan={plan}
                  onSelect={() => handlePlanSelect(plan)}
                  isSelected={selectedPlan?.name === plan.name}
                  colorIndexRef={colorIndexRef}
                  colors={colors}
                  //backgroundColor={currentBg}
                >
                  <Card.Title
                    title={plan.name}
                    titleStyle={[whypeStyles.cardTitle, styles.white]}
                    right={() =>
                      selectedPlan?.name === plan.name && (
                        <Icon
                          source="check-circle-outline"
                          size={30}
                          color="white"
                        />
                      )
                    }
                  />
                  <Card.Content>
                    <Text style={styles.white} variant="bodySmall">
                      Duration: {plan.duration} days
                    </Text>
                    <Text style={styles.white} variant="bodySmall">
                      Number of Whypes: {plan.whypes}
                    </Text>
                    <Text style={styles.white} variant="bodySmall">
                      Detail: {plan.detail}
                    </Text>
                    <Text style={styles.white} variant="bodySmall">
                      Amount: {"N"}
                      {plan.price}
                    </Text>
                  </Card.Content>
                </SelectionComponent>
              ))}
            </ScrollView>
          )}

          <View style={styles.mh}>
            <FlatList
              data={categories}
              horizontal
              keyExtractor={(item) => item.name}
              renderItem={({ item }) => (
                <Text
                  style={{
                    paddingHorizontal: 4,
                    color:
                      item === selectedCategory ? SECONDARY_COLOR : undefined,
                    height: 20,
                  }}
                  onPress={() => {
                    setSelectedCategory(item);
                    setSelectedPlan();
                    setTotal();
                  }}
                >
                  {item.name}
                </Text>
              )}
              refreshControl={
                <RefreshControl
                  refreshing={isLoading}
                  onRefresh={refreshCategories}
                />
              }
              scrollsToTop={true}
            />

            <Text
              variant="titleMedium"
              style={[styles.text.secondary, styles.m3]}
            >
              Total: N {total}
            </Text>
          </View>
          <View style={[styles.btnContainer]}>
            <Button
              icon="check"
              mode="contained"
              // style={[
              //   styles.actionBtn,
              //   {
              //     backgroundColor: SECONDARY_COLOR,
              //     width: "60%",
              //     // height: "80px",
              //     alignSelf: "center",
              //   },
              // ]}
              disabled={!selectedPlan}
              style={styles.btn.secondary}
              onPress={handleSubmit}
              textColor="white"
            >
              Continue
            </Button>
          </View>
        </ScrollView>
      )}
      {!categories.length > 0 && selectedCategory?.plans.length != 0 && (
        <View style={styles.empty}>
          <Text
            variant="titleMedium"
            style={[styles.text.secondary, styles.m3]}
          >
            No plans available please refresh or try again later
          </Text>
        </View>
      )}
      {!categories.length > 0 && selectedCategory?.plans.length == 0 && (
        <View style={styles.empty}>
          <Text
            variant="titleMedium"
            style={[styles.text.secondary, styles.m3]}
          >
            No categories available please refresh or try again later
          </Text>
        </View>
      )}
    </SafeAreaView>
  );
};

export default WhypePlanScreen;
