import React, { useState } from "react";

import { StyleSheet, View, Picker } from "react-native";
//import { Picker } from "@react-native-picker/picker";

import { Text, Button, TextInput } from "react-native-paper";
import {
  SECONDARY_COLOR,
  PRIMARY_COLOR,
  styles as main,
} from "../../utils/styles/main";
import { useTheme } from "../../utils/themes/ThemeContext";

const Step1 = ({
  form,
  setForm,
  handleSubmit,
  handleChange,
  prevStep,
  nextStep,
}) => {
  return (
    <View>
      <Text style={styles.steps}>Step 1 out of 3</Text>
      <Text style={styles.stepText} variant="labelLarge">
        Let's get to know you
      </Text>
      <TextInput
        label="Title"
        value={form.title}
        onChangeText={(value) => handleChange("title", value)}
        style={main.input}
        mode="outlined"
        maxLength={5}
        left={<TextInput.Affix text="Mr/Mrs..." />}
      />
      <TextInput
        label="Full Name"
        placeholder="James Kelly"
        value={form.fullName}
        onChangeText={(value) => handleChange("fullName", value)}
        style={main.input}
        mode="outlined"
        left={<TextInput.Icon icon="account" />}
        maxLength={24}
      />
      <Button
        onPress={nextStep}
        mode="contained"
        style={{
          alignSelf: "flex-end",
          backgroundColor: SECONDARY_COLOR,
        }}
        accessibilityLabel="Next step"
        icon="arrow-right-bold-circle"
        contentStyle={{ flexDirection: "row-reverse" }}
      >
        Next
      </Button>
    </View>
  );
};

const Step2 = ({
  form,
  setForm,
  handleSubmit,
  handleChange,
  prevStep,
  nextStep,
  fetchStates,
}) => {
  const [states, setStates] = useState([]);
  const { theme } = useTheme();
  // React.useEffect(() => {
  //   const loadStates = async () => {
  //     try {
  //       const { data } = await fetchStates();
  //       if (data) {
  //         setStates(data);
  //       }
  //     } catch (error) {}

  //   };
  //   loadStates();
  // });
  return (
    <View>
      <Text style={styles.steps}>Step 2 out of 3</Text>
      <Text style={styles.stepText} variant="labelLarge">
        Tell us about your work do and where you live{" "}
      </Text>
      <TextInput
        label="Occupation"
        value={form.occupation}
        placeholder="e.g. Doctor / Engineer"
        onChangeText={(value) => handleChange("occupation", value)}
        style={main.input}
        mode="outlined"
        maxLength={20}
        left={<TextInput.Icon icon="briefcase" />}
      />
      <TextInput
        label="State"
        value={form.state}
        onChangeText={(value) => handleChange("state", value)}
        style={main.input}
        mode="outlined"
        maxLength={20}
        left={<TextInput.Icon icon="home-group" />}
      />
      {/* <Text style={styles.stepText} variant="bodySmall">
            Select your state
          </Text>      
      <Picker
        selectedValue={form.state}
        onValueChange={(itemValue) => handleChange("state", itemValue)}
        style={[
          styles.picker,
          styles.input,
          {
            backgroundColor: theme.colors.background,
            color: theme.colors.primary,
          },
        ]}
      >
        <Picker.Item label={form.state} value={form.state} />
        {states?.map(
          (state, index) =>
            state !== form.state && <Picker.Item label={state} value={state} />
        )}
      </Picker> */}

      <TextInput
        label="City"
        value={form.city}
        onChangeText={(value) => handleChange("city", value)}
        style={main.input}
        mode="outlined"
        maxLength={20}
        left={<TextInput.Icon icon="home-city" />}
      />
      <Text style={styles.stepText} variant="labelLarge">
        Include a well known landmark (Religious center, building, bus stop or
        event center).
      </Text>
      <TextInput
        label="Address"
        value={form.address}
        onChangeText={(value) => handleChange("address", value)}
        style={main.input}
        mode="outlined"
        maxLength={50}
        placeholder="Street, House Number, etc."
        left={<TextInput.Icon icon="home-map-marker" />}
      />
      <View style={[main.row, { justifyContent: "space-around" }]}>
        <Button
          onPress={prevStep}
          mode="outlined"
          icon="arrow-left-bold-circle"
          labelStyle={styles.btnOutline}
          style={styles.btnOutline}
        >
          {" "}
          Previous step
        </Button>
        <Button
          onPress={nextStep}
          mode="contained"
          icon="arrow-right-bold-circle"
          style={styles.btn}
          contentStyle={{ flexDirection: "row-reverse" }}
        >
          Next
        </Button>
      </View>
    </View>
  );
};

const Step3 = ({
  form,
  setForm,
  handleSubmit,
  handleChange,
  prevStep,
  nextStep,
  isLoading,
}) => {
  const [showPassword, setShowPassword] = useState(true);

  return (
    <View>
      <Text style={styles.steps}>Step 3 out of 3</Text>
      <View style={styles.headingText}>
        <Text
          style={[styles.stepText, { display: "block" }]}
          variant="labelLarge"
        >
          Now set up your authentication details. You'll be using this to
          sign-in to your account
        </Text>
      </View>
      <Text style={styles.formFeedback} variant="labelSmall">
        Providing a phone number can help you with account recovery and enhances
        account security. Only for internal use.{" "}
      </Text>
      <TextInput
        label="Phone"
        placeholder="e.g. 2349012345678"
        value={form.phone}
        onChangeText={(value) => handleChange("phone", value)}
        style={main.input}
        mode="outlined"
        maxLength={15}
        //keyboardType="phone-pad"
        left={<TextInput.Icon icon="phone" />}
        inputMode="tel"
      />
      <Text style={styles.formFeedback} variant="labelSmall">
        Enter a valid email address
      </Text>
      <TextInput
        label="Email"
        value={form.email}
        placeholder="e.g. example@whypemasters.ng"
        onChangeText={(value) => handleChange("email", value)}
        style={main.input}
        mode="outlined"
        left={<TextInput.Icon icon="email-outline" />}
      />
      {/* <View>
          <Text style={styles.formFeedback} variant="labelLarge">
            Password must be
          </Text>
          <Text style={styles.formFeedback} variant="labelLarge">
            8 characters long, upper and lowercase characters.
          </Text>
          <Text style={styles.formFeedback} variant="labelLarge">
            Numbers and special characters.
          </Text>
          <Text style={styles.formFeedback}>{tt}</Text>
        </View> */}
      <Text style={styles.formFeedback} variant="labelSmall">
        A strong password is mostly\n8 characters long, upper and lowercase
        characters.\nNumbers and special characters.{" "}
      </Text>

      <TextInput
        label="Password"
        value={form.password}
        placeholder="Memoriable but strong password"
        onChangeText={(value) => handleChange("password", value)}
        style={main.input}
        mode="outlined"
        secureTextEntry={showPassword}
        left={<TextInput.Icon icon="form-textbox-password" />}
        right={
          <TextInput.Icon
            onPress={() => setShowPassword(!showPassword)}
            icon={showPassword ? "eye-off" : "eye"}
          />
        }
      />
      <View style={[main.row, { justifyContent: "space-around" }, main.m3]}>
        <Button
          onPress={prevStep}
          mode="outlined"
          icon="arrow-left-bold-circle"
          style={styles.btnOutline}
          labelStyle={styles.btnOutline}
        >
          {" "}
          Previous step
        </Button>
        <Button
          onPress={handleSubmit}
          mode="contained"
          style={styles.btn}
          loading={isLoading}
          //icon="send"
          //contentStyle={{ flexDirection: "row-reverse" }}
        >
          {" "}
          Submit{" "}
        </Button>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    //justifyContent: "center",
    //alignItems: "center",
    padding: 20,
  },
  heading: {
    fontSize: 24,
    //fontWeight: "bold",
    marginVertical: 20,
    textAlign: "left",
  },
  btn: {
    alignSelf: "flex-end",
    backgroundColor: SECONDARY_COLOR,
  },
  btnOutline: {
    borderColor: SECONDARY_COLOR,
    color: SECONDARY_COLOR,
    //marginHorizontal: 3,
  },

  extraInfo: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: 20,
  },
  formFeedback: {
    //color: "red",
  },
  stepText: {
    marginVertical: 5,
    color: PRIMARY_COLOR,
  },
  steps: {
    color: SECONDARY_COLOR,
    fontWeight: "bold",
  },
});

export { Step1, Step2, Step3 };
