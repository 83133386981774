import React, { useState } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  Image,
  Alert,
  Platform,
} from "react-native";
//import { Picker } from "@react-native-picker/picker";
import { openInBrowser } from "../../utils/WebJob";

import { Text, Button, TextInput, Dialog, Portal } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import Resources from "../../utils/Resources";

import {
  SECONDARY_COLOR,
  PRIMARY_COLOR,
  styles as main,
} from "../../utils/styles/main";
import ActionFeedback from "../../components/ActionFeedback";
//import UiAlert from "../../components/ActionFeedback";
import { useAuth } from "../../utils/AuthContext";
import { Step1, Step2, Step3 } from "./SignUpSteps";
import BreathingImage from "../../components/BreathingImage";

const SignUp = ({ navigation }) => {
  const [visible, setVisible] = React.useState(false);
  const showDialog = () => setVisible(true);
  const { register, isLoading, fetchStates } = useAuth();

  const hideDialog = () => setVisible(false);
  //const [terms, setTerms] = useState("https://whypemasters.ng");
  const [formIsValid, setFormIsValid] = useState(false);
  const errors = ["phone", "email", "password"];

  //console.log(App?.resources.)
  const terms = "";

  const [form, setForm] = useState({
    step: 1,
    title: "",
    // firstName: "",
    // middleName: "",
    // lastName: "",
    fullName: "",
    occupation: "",
    state: "",
    city: "",
    address: "",
    useLocation: false,
    phone: "",
    email: "",
    password: "",
  });
  const occupationOptions = [
    "Doctor",
    "Engineer",
    "Teacher",
    "Student",
    "Other",
  ];
  const feedbackAlert = (title, message) => {
    if (Platform.OS === "web") alert(message);
    else Alert.alert(title, message);
  };
  const nextStep = () => {
    if (form.step === 1) {
      if (!form.title || !form.fullName) {
        feedbackAlert(
          "Incomplete Step",
          "Please fill in your title and full name."
        );
        return;
      }
    } else if (form.step === 2) {
      if (!form.occupation || !form.state || !form.city || !form.address) {
        feedbackAlert(
          "Incomplete Step",
          "Please fill in all the fields in this step."
        );
        return;
      }
    } else if (form.step === 3) {
      if (!form.phone || !form.email || !form.password) {
        feedbackAlert(
          "Incomplete Step",
          "Please fill in all the fields in this step."
        );
        return;
      }
    } else {
      return;
    }
    setForm((prevState) => ({ ...prevState, step: prevState.step + 1 }));
  };

  const prevStep = () => {
    setForm((prevState) => ({ ...prevState, step: prevState.step - 1 }));
  };

  const handleChange = (name, value) => {
    setForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = () => {
    // Check if all values in state are not empty
    const isNotEmpty = Object.values(form).every((value) => value !== "");
    if (!isNotEmpty) {
      Alert.alert("Empty fields", "Please fill all fields before submitting");

      return;
    }

    showDialog();
    // Send form data to server
  };

  return (
    <SafeAreaView style={{ flex: 1, justifyContent: "center" }}>
      <ActionFeedback />
      <Portal>
        <Dialog visible={visible} onDismiss={hideDialog}>
          <Dialog.Title>
            <Text variant="titleLarge">Accept Terms & Conditions</Text>
          </Dialog.Title>
          <Dialog.Content>
            <Text>
              By creating an account, you agree you've read and accept our terms
              and conditions of service.
            </Text>
            <Button onPress={() => openInBrowser(terms)}>
              Please read our terms and conditions here
            </Button>
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              onPress={() => {
                // Add/Edit Account action
                hideDialog();
              }}
            >
              Cancel
            </Button>
            <Button
              onPress={() => {
                hideDialog();
                register(form);
              }}
            >
              Continue
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>

      <View style={{ flex: 1 }}>
        <ScrollView contentContainerStyle={{ justifyContent: "center" }}>
          <View style={styles.btnContainer}>
            <Button
              icon="arrow-left-bold-circle"
              onPress={() => navigation.navigate("SignIn")}
              style={{ alignSelf: "flex-start" }}
              textColor={main.text.primary.color}
            >
              Back to sign in
            </Button>
          </View>
          <BreathingImage
            style={styles.loginImage}
            source={require("../../assets/images/ICON_.png")}
          />
          <View style={styles.container}>
            <Text style={[styles.heading, main.text.primary]}>Get started</Text>

            {form.step === 1 && (
              <Step1
                form={form}
                setForm={setForm}
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                prevStep={prevStep}
                nextStep={nextStep}
              />
            )}

            {form.step === 2 && (
              <Step2
                form={form}
                setForm={setForm}
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                prevStep={prevStep}
                nextStep={nextStep}
                fetchStates={fetchStates}
              />
            )}

            {form.step === 3 && (
              <Step3
                form={form}
                setForm={setForm}
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                prevStep={prevStep}
                nextStep={nextStep}
                isLoading={isLoading}
              />
            )}
          </View>
          <Resources />
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    //justifyContent: "center",
    //alignItems: "center",
    padding: 20,
  },
  heading: {
    fontSize: 24,
    //fontWeight: "bold",
    marginVertical: 20,
    textAlign: "left",
  },
  btn: {
    alignSelf: "flex-end",
    backgroundColor: SECONDARY_COLOR,
  },
  btnContainer: {
    width: "50%",
    //alignItems: "center",
    //justifyContent: "flex-start",
  },
});
export default SignUp;
