import React from "react";

import { whypeStyles } from "../utils/styles/main";

import { Card, IconButton } from "react-native-paper";

const SelectionComponent = ({
  children,
  isSelected,
  onSelect,
  backgroundColor,
  colors,
  colorIndexRef,
}) => {
  const getNextColor = () => {
    const currentColorIndex = colorIndexRef.current;
    const nextColorIndex = (currentColorIndex + 1) % colors.length;
    colorIndexRef.current = nextColorIndex;
    return colors[currentColorIndex];
  };

  return (
    <Card
      style={[
        whypeStyles.card,
        isSelected ? whypeStyles.selectedCard : null,
        // { backgroundColor },
        { backgroundColor: getNextColor() },
      ]}
      onPress={onSelect}
    >
      {children}
    </Card>
  );
};

const Accordion = ({
  children,
  isSelected,
  onSelect,
  backgroundColor,
  colors,
  colorIndexRef,
  title,
}) => {
  const [showContent, setShowContent] = React.useState(false);
  const dropdown = React.useRef(false);

  const getNextColor = () => {
    const currentColorIndex = colorIndexRef.current;
    const nextColorIndex = (currentColorIndex + 1) % colors.length;
    colorIndexRef.current = nextColorIndex;
    return colors[currentColorIndex];
  };

  return (
    <Card style={{ backgroundColor: getNextColor() }}>
      <Card.Title
        title={title}
        titleVariant="bodySmall"
        right={() => (
          <IconButton
            onPress={() => {
              setShowContent(!showContent);
              dropdown.current = !dropdown.current;
            }}
            icon={!dropdown.current ? "arrow-down" : "arrow-up"}
          />
        )}
      />
      {/* {!showContent && { children }} */}
      {children}
    </Card>
  );
};

export { SelectionComponent, Accordion };
