import { Dimensions, StyleSheet } from "react-native";
const { width, height } = Dimensions.get("window");

const PRIMARY_COLOR = "#143791";
const SECONDARY_COLOR = "#0092e8";

const profileStyles = StyleSheet.create({
  container: {
    flex: 1,
    //backgroundColor: "#fff",
    justifyContent: "center",
  },
  row: {
    flexDirection: "row",
  },
  listTitle: {
    color: PRIMARY_COLOR,
    //fontSize: 18,
    fontWeight: "bold",
  },
  section: {
    padding: 16,
  },
  leftContent: {
    flex: 1, // Left content takes up 1/2 of the row
    marginRight: 16, // Add margin for spacing between left and right content
  },
  rightContent: {
    flex: 1, // Right content takes up 1/2 of the row
  },
  fullName: {
    marginTop: 30,
    marginLeft: 16,
    fontSize: 18,
    fontWeight: "bold",
  },
  action: {
    marginTop: 20,
    flex: 1,
    //marginLeft: 16,
    //justifyContent: "flex-end",
    //alignContent: "flex-end",
    alignItems: "flex-end",
  },
});

const main = StyleSheet.create({
  dateHeading: {
    fontSize: 20,
    //fontWeight: "bold",
    marginVertical: 10,
  },
  picker: {
    padding: "14px",
    borderRadius: 5,
    border: 1,
    borderWidth: 1,
    borderColor: "red",
  },
  text: {
    primary: {
      color: PRIMARY_COLOR,
    },
    secondary: {
      color: SECONDARY_COLOR,
    },
    color: "#FF5733",
    bold: {
      fontWeight: "bold",
    },
  },
  detailsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  leftRow: {
    flex: 1,
  },
  rightRow: {
    flex: 1,
    //alignItems: "center",
    justifyContent: "center",
    //alignSelf: "stretch",
    bottom: 25,
  },
  messageIcon: {
    fontSize: 24,
  },
  danger: {
    color: "#FF5733",
    backgroundColor: "red",
  },
  white: {
    color: "#ffffff",
  },
  headerImage: {
    width: "auto",
    //'18%'
    height: 40,
  },
  section: {
    height: "20%",
    borderTopWidth: 1,
    borderBottomWidth: 1,
    margin: 15,
  },
  safeArea: {
    flex: 1,
  },
  spaceBetween: {
    alignItems: "baseline",
  },
  stepText: {
    marginVertical: 5,
    color: PRIMARY_COLOR,
  },
  btnContainer: {
    width: "80%",
    marginBottom: "10px",
    justifyContent: "center",
    //paddingVertical: "14%",
    //alignItems: "flex-end",
    alignSelf: "center",
  },
  headingText: {
    marginBottom: 20,
  },
  siginInHeading: {
    fontSize: 24,
    //fontWeight: "bold",
    marginVertical: 20,
    textAlign: "left",
  },
  container: {
    flex: 1,
    //justifyContent: "center",
    //alignItems: "center",
    padding: 20,
  },
  contentCenter: {
    justifyContent: "center",
    alignItems: "center",
  },
  whyperPasscode: {
    padding: 5,
    minHeight: "5%",
    //borderStyle
    marginHorizontal: "10%",
    text: {
      padding: 1,
      textAlign: "center",
    },
  },
  dailyTip: {
    padding: 20,
    minHeight: "12%",
    //borderStyle
    //marginHorizontal: "5%",
  },
  recentActivities: {
    padding: 25,
    minHeight: "10%",
    //borderStyle
    //marginHorizontal: "10%",
  },
  dailyTipText: {
    padding: 1,
    color: "red",
  },
  p3: {
    padding: "15%",
  },
  pb: {
    paddingBottom: "5%",
  },
  flex1: {
    //justifyContent: 'center',
    flex: 1,
  },
  row: {
    flexDirection: "row",
  },
  alignEnd: {
    alignItems: "end",
  },
  alignStart: {
    alignItems: "start",
  },
  flexStart: {
    flexDirection: "start",
  },
  card: {
    //margin: 15,
    //backgroundColor: "blue",
    color: "#ffffff",
    text: {
      color: "#ffffff",
    },
  },
  cardTitle: {
    fontWeight: 400,
  },
  title: {
    fontWeight: 400,
  },
  textStart: {
    alignItems: "right",
  },
  w80: {
    width: "80%",
  },
  regularDivider: {
    height: 1, // Default height (non-bold)
    // width: '80%',
    marginHorizontal: "10%",
  },
  dailyTipHeader: {
    fontWeight: "bold",
  },
  actionBtn: {
    margin: 5,
    //flex: 1,
    //alignItems: "left",
  },
  actionBtn1: {
    backgroundColor: PRIMARY_COLOR,
  },
  btn: {
    primary: {
      backgroundColor: PRIMARY_COLOR,
    },
    secondary: {
      backgroundColor: SECONDARY_COLOR,
    },
  },
  m3: {
    margin: 10,
  },
  mh: {
    marginHorizontal: 25,
  },
  fab: {
    position: "absolute",
    margin: 16,
    right: 0,
    bottom: 0,
    backgroundColor: SECONDARY_COLOR,
    //color: "white",
  },
  paragraph: {
    //margin: 24,
    fontSize: 14,
    //fontWeight: "bold",
    //textAlign: "center",
    lineHeight: 26,
  },
  textCenter: {
    textAlign: "center",
  },
  textLeft: {
    textAlign: "left",
  },
  textRight: {
    textAlign: "right",
  },
  // loginImage: {
  //   width: "60%",
  //   height: "40%",
  //   resizeMode: "contain",
  //   //marginHorizontal: "20%",
  // },

  loginImage: {
    width: 120,
    height: 120,
    alignSelf: "center",
  },

  loginImageContainer: {
    //flex: 1,
    //justifyContent: "center",
    //alignItems: "center",
    //alignContent: "center",
  },
  loginInput: {
    margin: 5,
  },
  signInButton: {
    backgroundColor: SECONDARY_COLOR,
    marginVertical: 10,
    marginHorizontal: 30,
  },
  form: {
    //alignContent: "center",
    marginHorizontal: "10%",
  },
  empty: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    //marginHorizontal: "20%",
  },
  emptyWhype: {
    //flex: 1,
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    top: "50%",
    marginHorizontal: "20%",
  },
  bg: {
    primary: {
      backgroundColor: PRIMARY_COLOR,
    },
    secondary: {
      backgroundColor: SECONDARY_COLOR,
    },
  },
  input: {
    width: "100%",
    marginBottom: 10,
  },
  underline: {
    textDecorationLine: "underline",
  },
  bordered: {
    borderWidth: 1,
  },
});

const whypeStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  card: {
    //border: "none",
    marginHorizontal: "3%",
    marginVertical: 12,
  },
  cardTitle: {
    fontWeight: 400,
  },
  selectedCard: {
    borderWidth: 2,
    borderColor: SECONDARY_COLOR,
    //backgroundColor: SECONDARY_COLOR,
  },
  divider: {
    marginVertical: 16,
  },
  listItem: {
    margin: 16,
  },
  additionalContent: {
    margin: 16,
  },
});

const customButtonStyle = StyleSheet.create({
  buttonContainer: {
    flexDirection: "row",
  },
  iconWrapper: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 10, // Adjust the margin as needed
  },
  button: {
    flex: 1,
  },
});

export {
  main as styles,
  customButtonStyle,
  whypeStyles,
  profileStyles,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
};
