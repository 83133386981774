import React from "react";
import { StyleSheet, Dimensions, View, ScrollView } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useTheme } from "../utils/themes/ThemeContext";

const ResponsiveWrapper = ({ children }) => {
  const { width } = Dimensions.get("window");
  const { theme } = useTheme();

  return (
    <SafeAreaView style={[styles.container]}>
      {/* <ScrollView style={[styles.wrapper]}>{children}</ScrollView> */}
      {children}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  wrapper: {
    // width: "40%",
    // height: "40%",
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 2,
  },
});

export default ResponsiveWrapper;
