import * as React from "react";
import { Platform, StyleSheet, View, Text } from "react-native";
import { Snackbar, Portal, Button } from "react-native-paper";
import { useAuth } from "../utils/AuthContext";
import { Alert } from "react-native";
//import { styles } from "../utils/styles/main";

export const ActionFeedback = () => {
  const { message, setMessage } = useAuth();
  const timeMessage = () => {
    setTimeout(() => {
      setMessage(null);
    }, 5000);
  };
  return Platform.OS === "web" && message !== null ? (
    <View
      style={[
        styles.snackBar,
        //{ display: message !== null ? "block" : "none" },
      ]}
    >
      <Text
        style={{
          color: "#ffffff",
          //#bc8419
        }}
      >
        {message}
        {timeMessage()}
      </Text>
      <Button
        style={styles.snackBarButton}
        onPress={() => setMessage(null)}
        title="Ok"
      >
        Ok
      </Button>
    </View>
  ) : (
    <Portal>
      <Snackbar
        visible={message !== null}
        onDismiss={() => setMessage(null)}
        duration={5000}
        action={{
          label: "Ok",
          onPress: () => {
            // Do something
          },
        }}
      >
        {message}
      </Snackbar>
    </Portal>
  );
};

export const UiAlert = (title, message) => {
  Platform.OS == "web" ? alert(message) : Alert.alert(title, message);
};

const styles = StyleSheet.create({
  snackBar: {
    //display: 'none',
    flexDirection: "row",
    justifyContent: "space-between",
    zIndex: 1,
    top: 20,
    backgroundColor: "#657786",
    padding: 10,
    margin: 10,
    //flex: 1,
    height: "10%",
  },
  snackBarButton: {
    padding: 3,
    alignSelf: "flex-end",
  },
});

export default ActionFeedback;
