import { Linking, Platform } from "react-native";
import WebView from "react-native-webview";
import { UiAlert } from "../components/ActionFeedback";

const openInBrowser = (url) => {
  Linking.openURL(url)
    .then((supported) => {
      if (!supported) {
        //console.error("Cannot handle the URL:", url);
        UiAlert(`Unable to open ${url}`);
      }
    })
    .catch((err) =>
      UiAlert(`An error occurred while attempting to open ${url}`)
    );
};

const webResource = (url) => {
  try {
    if (Platform.OS === "web") window.open(url);
    else WebView(url);
  } catch (error) {
    openInBrowser(url);
  }
};

export { openInBrowser, webResource };
