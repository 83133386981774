import React, { useState } from "react";
import { StyleSheet, Pressable, View, Text, Dimensions } from "react-native";

import { styles as main } from "./styles/main";
import { useAuth } from "../utils/AuthContext";
import { webResource } from "./WebJob";

const Resources = () => {
  const { App } = useAuth();
  const links = App?.resources;

  return (
    <View style={styles.extraInfo}>
      {links?.map((link, index) => (
        <Pressable onPress={() => webResource(link.url)} key={index}>
          <Text style={main.text.primary}>{link?.title}</Text>
        </Pressable>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  extraInfo: {
    //flex: 1,
    alignContent: "stretch",
    flexDirection: "row",
    justifyContent: "space-around",
    margin: 20,
  },
});

export default Resources;
