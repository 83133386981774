import React, { useState } from "react";
// DatePickerIOS, Picker,
import { View, ScrollView, Alert } from "react-native";
import { Appbar } from "react-native-paper";
import { SECONDARY_COLOR, PRIMARY_COLOR, styles } from "../utils/styles/main";
import { SafeAreaView } from "react-native-safe-area-context";
import ActionFeedback from "../components/ActionFeedback";

//import { ScheduleComponent } from "../components/ScheduleComponent";
import { ScheduleComponent } from "../components/schedule/ScheduleComponent";

const ScheduleScreen = ({ navigation, route }) => {
  const { id, whypeName, remainingWhypes } = route.params;
  console.log("schedule screen id ", id);
  // const [whypeForm, setWhypeForm] = useState({
  //   whypeName: whypeName,
  //   selectedDate: selectedDate,
  //   selectedTime: selectedTime,
  //   selectedPeriod: selectedPeriod,
  // });
  console.log("schedukle screen remaining ", remainingWhypes);
  const combineDateTime = (date, time, period) => {
    const [hours, minutes] = time.split(":");
    let dateTime = new Date(date);
    dateTime.setHours(parseInt(hours) + (period === "PM" ? 12 : 0));
    dateTime.setMinutes(parseInt(minutes));
    return dateTime;
  };

  return (
    <SafeAreaView style={styles.safeArea}>
      <Appbar.Header statusBarHeight={0}>
        <Appbar.BackAction onPress={() => navigation.goBack()} />
        <Appbar.Content
          title={`Schedule whype - ${whypeName}`}
          mode="small"
          titleMaxFontSizeMultiplier={6}
          titleStyle={{ fontSize: 14 }}
        />
      </Appbar.Header>
      <ActionFeedback />
      <ScrollView contentContainerStyle={styles.flex1}>
        <View style={[styles.container, styles.flex1]}>
          <ScheduleComponent
            whypeName={whypeName}
            whypeId={id}
            remainingWhypes={remainingWhypes}
            navigation={navigation}
          />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default ScheduleScreen;
