// WhypesHistoryScreen.js
import React, { useState, useEffect } from "react";

import {
  View,
  ScrollView,
  FlatList,
  RefreshControl,
  StyleSheet,
} from "react-native";
import {
  Button,
  Text,
  Appbar,
  List,
  Divider,
  Portal,
  Modal,
  TextInput,
} from "react-native-paper";
import { styles, whypeStyles } from "../utils/styles/main";
import { SafeAreaView } from "react-native-safe-area-context";
//import {splash0, splash1} from '../utils/commonVars'
const containerStyle = { backgroundColor: "white", padding: 10 };
import { useAuth } from "../utils/AuthContext";
import ActionFeedback from "../components/ActionFeedback";

const history = [
  {
    id: 1,
    whype_type: ["Interior", "Exterior"],
    whype_start: "10:14 AM",
    whype_end: "12:34 PM",
    whype_date: "2023-07-15",
    whyper: {
      id: 1,
      username: "johndoe",
      first_name: "John",
      last_name: "Doe",
    },
  },
  {
    id: 2,
    whype_type: ["Exterior"],
    whype_start: "09:30 AM",
    whype_end: "11:45 AM",
    whype_date: "2023-07-16",
    whyper: {
      id: 2,
      username: "janedoe",
      first_name: "Jane",
      last_name: "Doe",
    },
  },
  {
    id: 3,
    whype_type: ["Interior", "Regular"],
    whype_start: "08:45 AM",
    whype_end: "10:15 AM",
    whype_date: "2023-07-17",
    whyper: {
      id: 3,
      username: "johndoe2",
      first_name: "John",
      last_name: "Smith",
    },
  },
  {
    id: 4,
    whype_type: ["Exterior"],
    whype_start: "10:00 AM",
    whype_end: "12:20 PM",
    whype_date: "2023-07-18",
    whyper: {
      id: 4,
      username: "janedoe2",
      first_name: "Jane",
      last_name: "Smith",
    },
  },
];

// Assuming your JSON data is stored in a variable named 'whypeHistoryData'

// function loadHistory(id) {
//   return new Promise((resolve, reject) => {
//     // Simulating an asynchronous operation
//     setTimeout(() => {
//       // Find the record with the matching 'id'
//       //const whypeHistory = history.whype_history.find((item) => item.id === id);
//       const whypeHistory = history;
//       if (whypeHistory) {
//         resolve(whypeHistory);
//       } else {
//         reject(`Whype History not found with id: ${id}`);
//       }
//     }, 100); // Simulate a delay (e.g., 100ms) for an asynchronous operation
//   });
// }

const Rating = () => {
  return (
    <View style={styles.m3}>
      <Text>Rate this whype</Text>
      <View style={styles.row}>
        <Button
          icon="emoticon-cry-outline"
          onPress={() => console.log("Pressed")}
        >
          1
        </Button>
        <Button
          icon="emoticon-sad-outline"
          onPress={() => console.log("Pressed")}
        >
          2
        </Button>
        <Button
          icon="emoticon-neutral-outline"
          onPress={() => console.log("Pressed")}
        >
          3
        </Button>
        <Button
          icon="emoticon-happy-outline"
          onPress={() => console.log("Pressed")}
        >
          4
        </Button>
        <Button
          icon="emoticon-excited-outline"
          onPress={() => console.log("Pressed")}
        >
          5
        </Button>
      </View>
    </View>
  );
};

const WhypeFeedBack = ({
  historyDate,
  feedbackText,
  setFeedBackText,
  sendFeedback,
  hideModal,
}) => {
  return (
    <View>
      <Text
        style={[
          styles.textCenter,
          { fontSize: 16, margin: "5%" },
          styles.text.primary,
        ]}
      >
        Feedback for {historyDate}
      </Text>

      {/* <Rating /> */}
      <Divider style={[{ marginBottom: "5%" }]} />

      <TextInput
        label="Feedback"
        value={feedbackText}
        mode="outlined"
        onChangeText={(feedbackText) => setFeedBackText(feedbackText)}
        multiline={true}
        style={styles.m3}
      />
      <View style={styles.row}>
        <Button
          style={styles.actionBtn}
          mode="contained"
          accessibilityHint="Cancel"
          accessibilityLabel="Cancel"
          onPress={() => hideModal()}
        >
          Cancel
        </Button>
        <Button
          style={styles.actionBtn}
          mode="contained"
          accessibilityHint="Send feedback"
          accessibilityLabel="Send feedback"
          onPress={() => sendFeedback()}
        >
          Send
        </Button>
      </View>
    </View>
  );
};

const WhypesHistoryScreen = ({ navigation, route }) => {
  // Use the whype id to load it's history
  // Show locally saved and refresh
  // id is the id of the whype and not the history
  const { id, whypeName } = route.params;
  const [whypeHistory, setWhypeHistory] = useState(null);
  const [refreshing, setRefreshing] = useState(false);
  const [isModalVisible, setModalVisible] = React.useState(false); // State for modal visibility
  const [feedbackText, setFeedBackText] = useState("");
  const [historyDate, setHistoryDate] = useState("");
  const showModal = () => setModalVisible(true);
  const hideModal = () => setModalVisible(false);
  const [feedbackSuccess, setFeedbackSuccess] = useState(false);
  // const [hasFeedback, setHasFeedback] = useState(false);
  const { loadWhypesHistory, sendWhypeFeedback } = useAuth();
  const [historyId, setHistoryId] = useState();

  const sendFeedback = async () => {
    hideModal();
    const { isSuccess } = await sendWhypeFeedback(id, historyId, feedbackText);
    if (isSuccess) setFeedbackSuccess(true);
  };

  const launchModal = (history_date, history_id) => {
    //WhypeFeedBack(history_date);
    setHistoryDate(history_date);
    setHistoryId(history_id);
    showModal();
  };
  // This is making the screen to re-render 4 times
  const refreshHistory = async () => {
    try {
      setRefreshing(true); // Set the refreshing state to true
      //const foundWhype = await loadHistory(id);
      const foundWhype = await loadWhypesHistory(id);
      setWhypeHistory(foundWhype);
      setRefreshing(false); // Set the refreshing state back to false after data is loaded
    } catch (error) {
      console.error(error);
      setRefreshing(false); // Set refreshing state to false in case of an error
    }
  };

  // Load the initial data when the screen loads
  useEffect(() => {
    refreshHistory();
  }, []);
  return (
    <SafeAreaView style={styles.safeArea}>
      <ActionFeedback />

      {/* Add the whype name and it's plan to json as whype_name e.g, whypa_name(plan)*/}
      <Appbar.Header statusBarHeight={0}>
        <Appbar.BackAction onPress={() => navigation.goBack()} />
        <Appbar.Content
          title={`Whype History - ${whypeName}`}
          style={styles.title}
          titleStyle={{ fontSize: 14 }}
        />
      </Appbar.Header>
      {whypeHistory && (
        <FlatList
          data={whypeHistory} // Assuming it's an array of WhypeHistory objects
          keyExtractor={(item) => item.id.toString()}
          renderItem={({ item }) => (
            <View style={{ padding: 15 }}>
              <Text style={[styles.dateHeading, styles.text.secondary]}>
                {item.whype_date}
              </Text>
              <View style={[styles.detailsContainer, styles.pb]}>
                <View style={styles.leftRow}>
                  <Text style={[styles2.paragraph, styles.text.secondary]}>
                    Whype Type: {item?.whype_type?.join(", ")}
                  </Text>
                  <Text style={[styles2.paragraph, styles.text.secondary]}>
                    Whype Start: {item.whype_start}
                  </Text>
                  <Text style={[styles2.paragraph, styles.text.secondary]}>
                    Whype End: {item.whype_end}
                  </Text>
                </View>
                <>
                  {!feedbackSuccess ||
                    (!item?.feedback && (
                      <View style={styles.rightRow}>
                        <Button
                          icon="chat-question-outline"
                          onPress={() =>
                            launchModal(item.whype_date, item.id.toString())
                          }
                          accessibilityLabel="Leave a feedback"
                          accessibilityHint="Leave a feedback"
                          style={{
                            color: styles.text.secondary,
                            alignSelf: "flex-end",
                          }}
                        >
                          Feedback
                        </Button>
                      </View>
                    ))}
                </>
              </View>
              <Divider />
            </View>
          )}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={refreshHistory}
            />
          }
        />
      )}

      <Portal>
        <Modal
          visible={isModalVisible}
          onDismiss={hideModal}
          contentContainerStyle={containerStyle}
          dismissableBackButton={true}
          dismissable={true}
        >
          {/* <Appbar.Header statusBarHeight={0}>
            <Appbar.Content title="Write a feedback" />
          </Appbar.Header> */}
          <WhypeFeedBack
            feedbackText={feedbackText}
            setFeedBackText={setFeedBackText}
            historyDate={historyDate}
            sendFeedback={sendFeedback}
            hideModal={hideModal}
          />
        </Modal>
      </Portal>
    </SafeAreaView>
  );
};

const styles2 = StyleSheet.create({
  paragraph: {
    //margin: 24,
    fontSize: 14,
    //fontWeight: "bold",
    //textAlign: "center",
    lineHeight: 26,
  },
});
export default WhypesHistoryScreen;
