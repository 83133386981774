import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";
import { StatusBar } from "expo-status-bar";
import * as SplashScreen from "expo-splash-screen";
import { ThemeProvider } from "./app/utils/themes/ThemeContext";
import { NavigationStateProvider } from "./app/navigation/NavigationContext";
import Navigation from "./app/navigation/Navigation";
import { AuthProvider } from "./app/utils/AuthContext";
import expo from "./app.json";
import { AppRegistry } from "react-native";

const AppName = expo.name;
// Keep the splash screen visible while we fetch resources
// SplashScreen.preventAutoHideAsync();

export default function App() {
  // const [appIsReady, setAppIsReady] = useState(false);
  // useEffect(() => {
  //   async function prepare() {
  //     try {
  //       // Pre-load fonts, make any API calls you need to do here
  //       await Font.loadAsync(Entypo.font);
  //       // Artificially delay for two seconds to simulate a slow loading
  //       // experience. Please remove this if you copy and paste the code!
  //       await new Promise((resolve) => setTimeout(resolve, 2000));
  //     } catch (e) {
  //       console.warn(e);
  //     } finally {
  //       // Tell the application to render
  //       setAppIsReady(true);
  //     }
  //   }

  //   prepare();
  // }, []);

  // const onLayoutRootView = useCallback(async () => {
  //   if (appIsReady) {
  //     // This tells the splash screen to hide immediately! If we call this after
  //     // `setAppIsReady`, then we may see a blank screen while the app is
  //     // loading its initial state and rendering its first pixels. So instead,
  //     // we hide the splash screen once we know the root view has already
  //     // performed layout.
  //     await SplashScreen.hideAsync();
  //   }
  // }, [appIsReady]);

  // if (!appIsReady) {
  //   return null;
  // }

  return (
    <>
      <StatusBar style="auto" />

      <SafeAreaProvider>
        <ThemeProvider>
          <AuthProvider>
            <NavigationStateProvider>
              <Navigation />
            </NavigationStateProvider>
          </AuthProvider>
        </ThemeProvider>
      </SafeAreaProvider>
    </>
  );
}

/*
    <SafeAreaProvider>
      <ThemeProvider>
        <AuthProvider>
          <NavigationStateContext>
            <NotificationProvider>
              <Navigation />
            </NotificationProvider>
          </NavigationStateContext>
        </AuthProvider>
      </ThemeProvider>
    </SafeAreaProvider>
*/

AppRegistry.registerComponent(AppName, () => App);
// registerRootComponent(App);
