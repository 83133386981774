import React, { useState } from "react";
import {
  View,
  ScrollView,
  Text,
  FlatList,
  RefreshControl,
  Platform,
  Modal,
} from "react-native";
import { Button, Card, Icon, Appbar, List } from "react-native-paper";
import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  styles,
  whypeStyles,
} from "../utils/styles/main";
import { useTheme } from "../utils/themes/ThemeContext";

import { useAuth } from "../utils/AuthContext";
import { SafeAreaView } from "react-native-safe-area-context";
import ActionFeedback from "./ActionFeedback";
import { StepIndicator } from "../components/BadgeComponent";

//Paystack
import { Paystack } from "react-native-paystack-webview";

const PaymentPortal = ({ navigation, route }) => {
  const {
    total,
    selectedPackage,
    selectedServices,
    selectedPlan,
    totalSteps,
    purchaseType,
    ...props
  } = route.params;
  let { completedSteps } = route.params;
  const [items, setItems] = useState(
    selectedPackage ? [selectedPackage, ...selectedServices] : [selectedPlan]
  );
  completedSteps = completedSteps + 1;
  const [searchText, setSearchText] = React.useState(""); // State to store the search text
  const [isModalVisible, setModalVisible] = React.useState(false); // State for modal visibility
  const showModal = () => setModalVisible(true);
  const hideModal = () => setModalVisible(false);
  const [isSearchVisible, setSearchVisible] = React.useState(false);
  const [usePrimary, setUsePrimary] = React.useState(false);
  const { user, isLoading, handlePurchase, App, setMessage, setIsloading } =
    useAuth();

  const [feedback, setFeedback] = useState({
    title: "",
    message: "",
    type: "",
  });
  const [modalContent, setModalContent] = useState(null);
  const [refreshing, setRefreshing] = useState(false);

  const [showMobilePayment, setShowMobilePayment] = useState(false);

  const [showPlans, setShowPlans] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);
  const [showPaymentError, setShowPaymentError] = useState(false);
  const [showPaymentLoading, setShowPaymentLoading] = useState(false);
  const [showPaymentFeedback, setShowPaymentFeedback] = useState(false);
  const [showPaymentFeedbackError, setShowPaymentFeedbackError] =
    useState(false);
  const [promptBirthday, setPromptBirthday] = useState(false);
  const showBirthdayPrompt = () => setPromptBirthday(true);
  const hideBirthdayPrompt = () => setPromptBirthday(false);
  const [paymentReference, setPaymentReference] = useState(null);
  const [retryPurchaseSubmit, setRetryPurchaseSubmit] = useState(false);
  const firstName = user.displayName.split()[0];
  const lastName = user.displayName.split()[1];

  // if (!items && items.length > 1) {
  //   React.useEffect(() => {

  //   })
  // }

  const acceptPayment = async ({ paymentRef }) => {
    let purchasedItems = {
      product: selectedPackage || selectedPlan,
      additionalServices: selectedServices || "",
      ...{
        paymentRef: paymentRef,
        total: total,
        purchaseType: purchaseType,
      },
    };
    try {
      const { success, message } = await handlePurchase(purchasedItems);
      if (success) {
        setShowPaymentSuccess(true);
        completedSteps++;
        setRetryPurchaseSubmit(false);
        navigation.navigate("Whypes");
      } else {
        setRetryPurchaseSubmit(true);
        setShowPaymentSuccess(false);
        setMessage(
          `${message} If error persist, please contact support and provide them with your payment reference - ${paymentReference}`
        );
      }
    } catch (error) {
      setRetryPurchaseSubmit(true);
      setMessage(
        `${error} If error persist, please contact support and provide them with your payment reference - ${paymentReference}`
      );
    }
  };

  const webPay = () => {
    //<script src="https://js.paystack.co/v1/inline.js"></script>
    const handler = PaystackPop.setup({
      key: `${App.paystack_public_key}`,
      //email: App.email,
      email: user.email,
      amount: total * 100,
      currency: "NGN",
      //ref: "YOUR_REFERENCE",
      callback: function (response) {
        var reference = response?.reference;
        //alert("Payment complete! Reference: " + reference);
        setPaymentReference(reference);
        acceptPayment({ paymentRef: reference });
      },
      onClose: function () {
        //alert("Transaction was not completed, window closed.");
        setMessage("Transaction was not completed, window closed.");
        setIsloading(false);
      },
    });
    handler.openIframe();
  };

  const handleSubmit = async () => {
    if (retryPurchaseSubmit) {
      await acceptPayment({ paymentRef: paymentReference });
    } else {
      if (Platform.OS === "web") {
        webPay();
      } else {
        setShowMobilePayment(true);
      }
    }
  };

  const { theme } = useTheme();
  console.log("THEME ", theme);
  return (
    <SafeAreaView style={styles.safeArea}>
      <ActionFeedback />
      <Appbar.Header statusBarHeight={0}>
        <Appbar.BackAction
          onPress={() => {
            navigation.goBack();
          }}
          accessibilityLabel="Go back"
        />
        <Appbar.Content title="Make payment" titleStyle={{ fontSize: 14 }} />
      </Appbar.Header>
      <StepIndicator totalSteps={totalSteps} completedSteps={completedSteps} />

      <ScrollView>
        <View style={[styles.container]}>
          <List.Section>
            {/* <List.Subheader>Payment notice</List.Subheader> */}
            <Text variant="bodySmall" style={styles.text.secondary}>
              We'll be using you saved account information for this payment. You
              can change it at anytime on your profile page.
            </Text>
          </List.Section>
          <List.Section>
            <List.Subheader style={[styles.m3, styles.bordered]}>
              {`Your order ${
                purchaseType === "renew" ? "renewal" : ""
              } summary`}
            </List.Subheader>
            {items && items.length >= 1 && (
              <>
                {items?.map((item, index) => (
                  <List.Item
                    title={item?.name}
                    key={index}
                    description={`N ${item?.price}`}
                    left={(props) => <List.Icon {...props} icon="check" />}
                    descriptionStyle={{ fontWeight: "bold" }}
                  />
                ))}
                {purchaseType === "renew" && (
                  <Text
                    variant="bodySmall"
                    style={[styles.text.secondary, styles.pb]}
                  >
                    Please note that for renewal, some additional items linked
                    to a subscription may not be listed here.
                  </Text>
                )}
                <View style={[styles.m3, styles.bordered]}>
                  <Text
                    variant="titleMedium"
                    style={[
                      styles.text.secondary,
                      styles.textCenter,
                      styles.m3,
                      { fontWeight: "bold" },
                    ]}
                  >
                    You are about to pay: N {total}
                  </Text>
                </View>
              </>
            )}
          </List.Section>
        </View>
      </ScrollView>
      {showMobilePayment && (
        <Paystack
          paystackKey={App.paystack_public_key}
          amount={total}
          billingEmail={App.email}
          phone={user.phoneNumber}
          firstName={firstName}
          lastName={lastName}
          activityIndicatorColor={PRIMARY_COLOR}
          onCancel={(e) => {
            // handle response here
            setMessage(e);
            setIsloading(false);
          }}
          onSuccess={async (res) => {
            const reference = res.transactionRef;
            setPaymentReference(reference);
            await acceptPayment({ paymentRef: reference });
          }}
          autoStart={true}
        />
      )}
      <View style={[styles.btnContainer, styles.m3]}>
        <Button
          onPress={() => handleSubmit()}
          mode="contained"
          style={styles.btn.primary}
          disabled={isLoading}
          textColor="white"
        >
          {retryPurchaseSubmit ? "Retry payment confirmation" : "Pay now"}
        </Button>
      </View>
    </SafeAreaView>
  );
};

export default PaymentPortal;
