import { setItemAsync, getItemAsync, deleteItemAsync } from "expo-secure-store";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Platform } from "react-native";

// We can do the json parsing and stringfying here instead

var setItem, getItem, removeItem;
if (Platform.OS === "web") {
  setItem = async (key, value) => {
    await AsyncStorage.setItem(key, value);
  };

  getItem = async (key) => {
    const value = await AsyncStorage.getItem(key);
    return value;
  };

  removeItem = async (key, callback) => {
    await AsyncStorage.removeItem(key, callback);
  };
} else {
  setItem = async (key, value) => {
    await setItemAsync(key, value);
  };

  getItem = async (key) => {
    const item = await getItemAsync(key);
    return item;
  };

  removeItem = async (key) => {
    await deleteItemAsync(key);
  };
}

export { setItem, getItem, removeItem };
