// AddScheduleForm.js
import React, { useEffect, useState } from "react";
import { View, ScrollView, Picker } from "react-native";
import { TextInput, Button, Text } from "react-native-paper";

const AddScheduleForm = ({
  workStartTime,
  workEnndTime,
  skipDays,
  newScheduleDay,
  setNewScheduleDay,
  newScheduleTime,
  setNewScheduleTime,
  newScheduleAdditionalInfo,
  setNewScheduleAdditionalInfo,
  handleAddNewSchedule,
  style,
  theme,
  addNew,
  setAddNew,
  cannotAdd,
  scheduleMessage,
}) => {
  const [generatedTimes, setGeneratedTimes] = useState([]);

  const getFormattedDate = (date) => {
    const options = {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

  // Helper function to format time as "HH:mm AM/PM"
  const formatTime = (time) => {
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    return new Intl.DateTimeFormat("en-US", options).format(time);
  };

  const DaysItem = () => {
    const currentDate = new Date();
    const resultDates = [];

    while (resultDates.length < 7) {
      if (resultDates.length >= 1) {
        currentDate.setDate(currentDate.getDate() + 1);
      } else {
        currentDate.setDate(currentDate.getDate());
      }
      // Check if the current day should be skipped
      if (
        skipDays.includes(
          currentDate.toLocaleDateString("en-US", { weekday: "short" })
        )
      ) {
        continue;
      }

      const formattedDate = getFormattedDate(currentDate);
      resultDates.push(
        <Picker.Item
          key={formattedDate}
          label={formattedDate}
          value={formattedDate}
        />
      );
    }
    return resultDates;
  };

  const TimeItems = () => {
    return generatedTimes.map((time) => (
      <Picker.Item key={time.id} label={time.start} value={time.start} />
    ));
  };
  // Set default value when the component mounts

  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = getFormattedDate(currentDate);
    setNewScheduleDay(formattedDate);

    const currentHour = currentDate.getHours();
    const currentMinute = currentDate.getMinutes();
    const initialMinutes = currentMinute + 40;

    let startTime = new Date();
    startTime.setHours(workStartTime || currentHour);
    startTime.setMinutes(initialMinutes);

    const endTime = new Date();
    endTime.setHours(workEnndTime || 19);

    const times = [];

    while (startTime <= endTime) {
      const time = {
        id: times.length + 1,
        start: formatTime(startTime),
      };

      times.push(time);
      startTime.setMinutes(startTime.getMinutes() + 60);
    }

    setGeneratedTimes(times);
    setNewScheduleTime(times[0].start);
    //setNewScheduleAmPm("AM");
  }, []); // Empty dependency array ensures it runs only once on mount

  return (
    <>
      <View>
        <Text variant="bodySmall" style={style.pb}>
          {!cannotAdd
            ? scheduleMessage
            : "You can not add schedules more than your available whypes."}
        </Text>

        <ScrollView
          horizontal
          contentContainerStyle={{ justifyContent: "space-around" }}
        >
          <Picker
            selectedValue={newScheduleDay}
            onValueChange={(itemValue) => setNewScheduleDay(itemValue)}
            style={[
              style.picker,
              {
                backgroundColor: theme.colors.background,
                color: theme.colors.primary,
              },
            ]}
          >
            <DaysItem />
          </Picker>
          <Picker
            selectedValue={newScheduleTime}
            onValueChange={(itemValue) => setNewScheduleTime(itemValue)}
            style={[
              style.picker,
              {
                backgroundColor: theme.colors.background,
                color: theme.colors.primary,
                marginHorizontal: 5,
              },
            ]}
          >
            <TimeItems />
          </Picker>

          {/* <Picker
            selectedValue={newScheduleAmPm}
            onValueChange={(itemValue) => setNewScheduleAmPm(itemValue)}
            style={[
              style.picker,
              {
                backgroundColor: theme.colors.background,
                color: theme.colors.primary,
              },
            ]}
          >
            <Picker.Item label="AM" value="AM" />
            <Picker.Item label="PM" value="PM" />
          </Picker> */}
          {/* <SegmentedButtons
              value={""}
              onValueChange={""}
              buttons={[
                {
                  value: "AM",
                  label: "AM",
                },
                {
                  value: "PM",
                  label: "PM",
                },
              ]}
              style={{ alignItems: "center" }}
            /> */}
        </ScrollView>
        {/* ... (TextInput and Button components) */}
      </View>
      <TextInput
        label="Optional detail"
        value={newScheduleAdditionalInfo}
        onChangeText={(text) => setNewScheduleAdditionalInfo(text)}
        style={style.input}
        mode="outlined"
        maxLength={150}
        focusable={true}
        multiline={true}
      />
      {/* <View style={style.alignEnd}>
        <Button
          icon={!addNew ? "plus-circle" : "minus-circle"}
          onPress={() => setAddNew(!addNew)}
          contentStyle={{ flexDirection: "row-reverse" }}
        >
          {!addNew ? "Add new" : "Hide"}
        </Button>
      </View> */}
      <View style={[style.btnContainer, style.row, style.contentCenter]}>
        <Button
          mode="contained"
          icon={"check"}
          disabled={cannotAdd}
          onPress={handleAddNewSchedule}
          style={[style.actionBtn1, { margin: 10 }]}
          accessibilityLabel="Add a new schedule"
          textColor="white"
          contentStyle={{ flexDirection: "row-reverse" }}
        >
          Add & continue
        </Button>
      </View>
    </>
  );
};

export default AddScheduleForm;
