import React, { useState } from "react";

import { View, ScrollView, Platform, Alert } from "react-native";
import { Picker } from "@react-native-picker/picker";
import { Text, Button, TextInput, Appbar } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";

import { PRIMARY_COLOR, styles } from "../../utils/styles/main";
import ActionFeedback from "../../components/ActionFeedback";
import { useAuth } from "../../utils/AuthContext";
import { useTheme } from "../../utils/themes/ThemeContext";

const EditProfile = ({ navigation }) => {
  const { isLoading, user, updateProfile, fetchStates } = useAuth();
  //const [updated, setUpdated] = useState(false);
  const [states, setStates] = useState([]);
  const { theme } = useTheme();

  // React.useEffect(() => {
  //   const loadStates = async () => {
  //     try {
  //       const { data } = await fetchStates();
  //       if (data) {
  //         setStates(data);
  //       }
  //     } catch (error) {}
  //   };
  //   loadStates();
  // });

  const loadStates = async () => {
    try {
      const { data } = await fetchStates();
      if (data) {
        setStates(data);
      }
    } catch (error) {}
  };

  const [form, setForm] = useState({
    email: user?.email,
    title: user?.title,
    fullName: user?.fullName,
    occupation: user?.occupation,
    phone: `${user?.phoneNumber}`.replace("+", ""),
    state: user?.state,
    city: user?.city,
    address: user?.address,
    zip: `${user?.zip}`,
    //useLocation: false,
  });
  const feedbackAlert = (title, message) => {
    if (Platform.OS === "web") alert(message);
    else Alert.alert(title, message);
  };
  // Looping through and updating seem to be the reason the form is slow to switch between fields
  const handleChange = (name, value) => {
    setForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async () => {
    // Check if all values in state are not empty
    const isNotEmpty = Object.values(form).every((value) => value !== "");
    if (!isNotEmpty) {
      feedbackAlert("Empty fields error", "Please fill in all fields.");
      return;
    }

    // Send form data to server
    const isUpdated = await updateProfile(form);

    if (isUpdated) navigation.goBack();
  };

  return (
    <SafeAreaView style={styles.safeArea}>
      <Appbar.Header statusBarHeight={0}>
        <Appbar.BackAction onPress={() => navigation.goBack()} />
        <Appbar.Content title="Edit Profile" titleStyle={{ fontSize: 14 }} />
      </Appbar.Header>
      <ActionFeedback />

      <ScrollView style={{ width: "100%" }}>
        <View style={styles.container}>
          <View style={styles.headingText}>
            <Text style={styles.text.primary} variant="bodySmall">
              Making changes to your profile is a great way to keep your
              information up-to-date and enhance your experience on our
              platform.
            </Text>
          </View>
          <TextInput
            label="Title"
            value={form.title}
            onChangeText={(value) => handleChange("title", value)}
            style={styles.input}
            mode="outlined"
            left={<TextInput.Affix text="Mr/Dr..." />}
            focusable={true}
          />

          <Text style={styles.stepText} variant="bodySmall">
            Use your full legal name, so we could verify your identity and
            quickly resolve any issues that may arise with your occount.
          </Text>
          <TextInput
            label="Full Name"
            placeholder="James Kelly"
            value={form.fullName}
            onChangeText={(value) => handleChange("fullName", value)}
            style={styles.input}
            mode="outlined"
            left={<TextInput.Icon icon="account" />}
            maxLength={24}
          />
          <Text style={styles.stepText} variant="bodySmall">
            Providing a phone number can help you with account recovery and
            enhances account security. Only for internal use.
          </Text>
          <TextInput
            label="Phone Number"
            placeholder="e.g. 2349012345678"
            value={form.phone}
            onChangeText={(value) => handleChange("phone", value)}
            style={styles.input}
            mode="outlined"
            left={<TextInput.Icon icon="phone" />}
            //keyboardType="phone-pad"
            maxLength={15}
            focusable={true}
          />

          <TextInput
            label="Occupation"
            value={form.occupation}
            placeholder="e.g. Doctor / Engineer"
            onChangeText={(value) => handleChange("occupation", value)}
            style={styles.input}
            mode="outlined"
            maxLength={20}
            left={<TextInput.Icon icon="briefcase" />}
            focusable={true}
          />
          <TextInput
            label="State"
            value={form.state}
            onChangeText={(value) => handleChange("state", value)}
            style={styles.input}
            mode="outlined"
            maxLength={20}
            left={<TextInput.Icon icon="home-group" />}
            focusable={true}
          />
          {/* <Text style={styles.stepText} variant="bodySmall">
            Select your state
          </Text>
          <Picker
            selectedValue={form.state}
            onValueChange={(itemValue) => handleChange("state", itemValue)}
            aria-label="State"
            label="State"
            style={[
              styles.picker,
              styles.input,
              {
                backgroundColor: theme.colors.background,
                color: theme.colors.primary,
              },
            ]}
          >
            <Picker.Item label={form.state} value={form.state} />
            {states &&
              states.length > 0 &&
              states?.map(
                (state, index) =>
                  state !== form.state && (
                    <Picker.Item label={state} value={state} />
                  )
              )}
          </Picker> */}
          <TextInput
            label="City"
            value={form.city}
            onChangeText={(value) => handleChange("city", value)}
            style={styles.input}
            mode="outlined"
            maxLength={20}
            left={<TextInput.Icon icon="home-city" />}
            focusable={true}
          />
          <TextInput
            label="Zip / Postal Code"
            value={form.zip}
            onChangeText={(value) => handleChange("zip", value)}
            style={styles.input}
            mode="outlined"
            maxLength={20}
            left={<TextInput.Icon icon="map-marker-path" />}
            focusable={true}
          />
          <Text style={styles.stepText} variant="bodySmall">
            Accurate location information makes it easier to connect you with
            our workers near you or in your area
          </Text>
          <TextInput
            label="Address"
            value={form.address}
            onChangeText={(value) => handleChange("address", value)}
            style={styles.input}
            mode="outlined"
            maxLength={50}
            placeholder="Street, House Number, etc."
            left={<TextInput.Icon icon="home-map-marker" />}
            focusable={true}
          />
          <View style={styles.btnContainer}>
            <Button
              onPress={handleSubmit}
              mode="contained"
              style={styles.btn.primary}
              loading={isLoading}
              disabled={isLoading}
              textColor="white"

              //icon="send"
              //contentStyle={{ flexDirection: "row-reverse" }}
            >
              Save changes
            </Button>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default EditProfile;
