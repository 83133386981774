import React from "react";
import { setItem, getItem, removeItem } from "../utils/storage/Storage";

const NavigationStateContext = React.createContext(null);
const PERSISTENCE_KEY = "NAVIGATION_STATE_V1";

export const NavigationStateProvider = ({ children }) => {
  //const [notification, setNotification] = React.useState();

  // let state = 0;
  let state;
  const restoreState = async () => {
    try {
      state = await getItem(PERSISTENCE_KEY);
      await setItem(PERSISTENCE_KEY, state);
    } catch (error) {}
  };

  restoreState();
  const [navigationState, setNavigationState] = React.useState(state);

  return (
    <NavigationStateContext.Provider
      value={{ navigationState, setNavigationState }}
    >
      {children}
    </NavigationStateContext.Provider>
  );
};

export const useNavigationStateContext = () =>
  React.useContext(NavigationStateContext);
// export default NavigationStateContext;
