// ThemeContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { setItem, getItem } from "../storage/Storage";
import {
  Provider as PaperProvider,
  useTheme as paperTheme,
} from "react-native-paper";
import { MD3DarkTheme, MD3LightTheme } from "react-native-paper";
import { themeEventEmitter } from "./ThemeEvents";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../styles/main";

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [isDark, setIsDark] = useState(false);
  const [theme, changeTheme] = useState();
  const checkDark = (state) => {
    changeTheme(
      state
        ? {
            ...MD3DarkTheme,
            ...{
              primary: PRIMARY_COLOR,
              secondary: SECONDARY_COLOR,
            },
          }
        : {
            ...MD3LightTheme,
            ...{ primary: PRIMARY_COLOR, secondary: SECONDARY_COLOR },
          }
    );
    setIsDark(state);
    // Notify listeners that the theme has changed
    themeEventEmitter.emit("themeChanged", state);
  };
  useEffect(() => {
    const loadTheme = async () => {
      try {
        const value = await getItem("isDark");
        if (value === "true") {
          checkDark(true);
        } else {
          checkDark(false);
        }
      } catch (error) {}
    };

    loadTheme();
  }, []);

  const toggleTheme = () => {
    const newIsDark = !isDark;
    setItem("isDark", newIsDark.toString());
    checkDark(newIsDark);
  };
  return (
    <ThemeContext.Provider value={{ isDark, toggleTheme, theme }}>
      <PaperProvider theme={theme}>{children}</PaperProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
