import React, { useState, useRef } from "react";

import { View, FlatList, RefreshControl, ScrollView } from "react-native";
import {
  Card,
  Text,
  Searchbar,
  FAB,
  //Modal,
  Dialog,
  Portal,
  Tooltip,
  Button,
  Appbar,
  Icon,
} from "react-native-paper";
import { useAuth } from "../utils/AuthContext";
import { useTheme } from "../utils/themes/ThemeContext";
import { convertDate } from "../utils/DateTIme";

import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  styles,
  whypeStyles,
} from "../utils/styles/main";
import { SafeAreaView } from "react-native-safe-area-context";
//import { splash0, splash1 } from "../utils/commonVars";
//import InfoModal from "../components/InfoModal";
import ActionFeedback from "../components/ActionFeedback";
import { Pressable } from "react-native";
import { SelectionComponent } from "../components/SelectionComponents";

const WhypesScreen = ({ navigation, route }) => {
  // Only do stuff when components is mounted
  const [searchText, setSearchText] = React.useState("");
  const [isModalVisible, setModalVisible] = React.useState(false);
  const showModal = () => setModalVisible(true);
  const hideModal = () => setModalVisible(false);
  const [isSearchVisible, setSearchVisible] = React.useState(false);

  const [dialogVisible, setDialogVisible] = React.useState(false);
  const showDialog = () => setDialogVisible(true);
  const hideDialog = () => setDialogVisible(false);

  const [fabVisible, setFabVisible] = React.useState(true);
  const hideFAB = () => setFabVisible(false);
  const showFAB = () => setFabVisible(true);

  const { whypes, loadWhypes, isLoading } = useAuth();
  const { theme } = useTheme();
  // Do actions passed to this screen
  // console.log("PARAMS ", route.params);
  // const { action } = route.params;
  // console.log("Action ", action);
  // switch (action) {
  //   case "create":
  //     () => setModalVisible(true);
  //     break;

  //   default:
  //     break;
  // }

  const colors = [PRIMARY_COLOR, SECONDARY_COLOR];
  const colorIndexRef = useRef(0);
  const WhypeCard = ({ planData }) => {
    const {
      id,
      plan,
      name,
      completed_whypes,
      remaining_whypes,
      paid,
      service_type,
      created_at,
      has_history,
      icon,
      renewal_price,
    } = planData;
    const handleCardPress = () => {
      navigation.navigate("WhypeHistory", {
        id: id,
        whypeName: name || plan,
      });
    };

    const handleSchedule = () => {
      navigation.navigate("Schedule", {
        id: id,
        whypeName: name || plan,
        remainingWhypes: remaining_whypes,
      });
    };
    //const backgroundColor = switchBg();
    //setUsePrimary(true);

    const handleRenew = ({ id }) => {
      // Renew whype of id: id
      // Move to payment portal, server should check if
      // whype with the id already exist, so it'll be noted as renewed.
      navigation.navigate("PaymentPortal", {
        total: renewal_price,
        selectedPackage: {
          id: id,
          name: name || plan,
          price: renewal_price,
        },
        selectedServices: [],

        purchaseType: "renew",
      });
    };

    // const [currentBg, setCurrentBg] = React.useState(theme.primary);
    // if (currentBg === theme.primary) {
    //   setCurrentBg(theme.secondary);
    // } else {
    //   setCurrentBg(theme.primary);
    // }
    return (
      <>
        <SelectionComponent colorIndexRef={colorIndexRef} colors={colors}>
          <Card.Title
            title={name || plan}
            titleStyle={[whypeStyles.cardTitle, styles.white]}
            right={() =>
              has_history && (
                <Pressable onPress={handleCardPress}>
                  <Text style={[styles.white, styles.mh, styles.underline]}>
                    Whype history
                  </Text>
                </Pressable>
              )
            }
          />
          <Card.Content
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <View>
              <Text style={styles.white} variant="bodySmall">
                Plan: {plan}
              </Text>
              <Text style={styles.white} variant="bodySmall">
                Completed: {completed_whypes}
              </Text>
              <Text style={styles.white} variant="bodySmall">
                Remaining: {remaining_whypes}
              </Text>
              <Text style={styles.white} variant="bodySmall">
                Service type: {service_type}
              </Text>
              <Text style={styles.white} variant="bodySmall">
                Payment status: {paid ? "Paid" : "Not Paid"}
              </Text>
              <Text style={styles.white} variant="bodySmall">
                Created by: {convertDate(created_at)}
              </Text>
            </View>
            <View style={{ alignItems: "flex-end" }}>
              <Icon
                source={icon}
                color={styles.white.color}
                size={70}
                style={{ marginEnd: "15%" }}
              />
              {/* {remaining_whypes === 0 || !paid ? ( */}
              {remaining_whypes === 0 ? (
                <Tooltip title="Pay or Renew whype">
                  <Pressable
                    onPress={() => handleRenew({ id })}
                    accessibilityLabel="Pay or Renew whype"
                  >
                    <Icon
                      source="history"
                      color={styles.white.color}
                      size={30}
                      style={{ marginEnd: "15%" }}
                    />
                  </Pressable>
                </Tooltip>
              ) : (
                <Tooltip title="Schedule whype">
                  <Pressable
                    onPress={() => handleSchedule()}
                    accessibilityLabel="Schedule whype"
                  >
                    <Icon
                      source="square-edit-outline"
                      color={styles.white.color}
                      size={30}
                      style={{ marginEnd: "15%" }}
                    />
                  </Pressable>
                </Tooltip>
              )}
            </View>
          </Card.Content>
        </SelectionComponent>
      </>
    );
  };

  const refreshWhypes = async () => {
    try {
      loadWhypes();
    } catch (error) {
      //console.error(error);
    }
  };
  const filteredWhypes = whypes?.filter((plan) => {
    // Check if the plan's name includes the search text
    return plan.name.toLowerCase().includes(searchText.toLowerCase());
  });

  const handleSearchIconPress = () => {
    if (isSearchVisible) setSearchVisible(false);
    else setSearchVisible(true);
  };

  const handleSearchTextChange = (text) => {
    setSearchText(text);
  };

  const hideSearchBar = () => {
    setSearchText("");
    setSearchVisible(false);
  };
  const [scrollOffset, setScrollOffset] = useState(0);
  const handleScroll = (event) => {
    const currentOffset = event.nativeEvent.contentOffset.y;
    // const isFabVisible = currentOffset <= 0;
    // console.log(
    //   `Current offset - ${currentOffset} is visible - ${isFabVisible}`
    // );
    if (currentOffset > scrollOffset) hideFAB();
    else showFAB();
    console.log("FAB VISIBLE ", fabVisible);
    setScrollOffset(currentOffset);

    // if (isFabVisible !== fabVisible) {
    //   setFabVisible(isFabVisible);
    // }
  };

  return (
    <SafeAreaView style={styles.safeArea}>
      <ActionFeedback />
      <Portal>
        <Dialog visible={dialogVisible} onDismiss={hideDialog}>
          <Dialog.Title>
            <Text variant="titleLarge">Choose action</Text>
          </Dialog.Title>
          <Dialog.Content>
            <Text>
              Do you want to create a plan or book a whype straight away?
            </Text>
            <View style={styles.row}>
              <Button
                onPress={() => {
                  navigation.navigate("Booking");
                  hideDialog();
                }}
              >
                Book a whype
              </Button>
              <Button
                onPress={() => {
                  hideDialog();
                  console.log("Create a plan");
                  navigation.navigate("CreatePlan");
                }}
                //style={styles.actionBtn1}
                //mode="outlined"
              >
                Create a plan
              </Button>
            </View>
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              onPress={() => {
                hideDialog();
              }}
            >
              Cancel
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
      <View
        style={{
          width: "100%",
        }}
      >
        <Appbar.Header statusBarHeight={0}>
          <Appbar.Content title="My whypes" titleStyle={{ fontSize: 14 }} />
          <Appbar.Action icon="magnify" onPress={handleSearchIconPress} />
        </Appbar.Header>
        {isSearchVisible && (
          <Searchbar
            placeholder="Search for plans"
            onChangeText={handleSearchTextChange}
            value={searchText}
            style={{ marginHorizontal: "10%" }}
            onIconPress={hideSearchBar}
            onBlur={hideSearchBar}
            // Handle onSubmit as needed
            // onSubmitEditing={handleSearchSubmit}
          />
        )}
      </View>
      {whypes.length > 0 && (
        <FlatList
          data={filteredWhypes}
          renderItem={({ item }) => (
            <WhypeCard planData={item} key={item.toString()} />
          )}
          keyExtractor={(item) => item.toString()}
          refreshControl={
            <RefreshControl refreshing={isLoading} onRefresh={refreshWhypes} />
          }
          // onScrollBeginDrag={() => {
          //   hideFAB();
          // }}
          // onScrollEndDrag={() => {
          //   showFAB();
          // }}
          // onScrollAnimationEnd={() => {
          //   console.log("Animation ended");
          // }}
          onScroll={handleScroll}
          scrollEventThrottle={12}
        />
      )}
      {whypes.length < 1 && (
        <View style={styles.empty}>
          <Text style={[styles.text.secondary, styles.m3]}>
            You don't have any Whypes yet.
          </Text>
          <Text style={[styles.text.secondary, styles.m3]}>
            Please swipe down to refresh if you already have Whypes.
          </Text>
        </View>
      )}
      <Tooltip title="Create a new Whype">
        <FAB
          icon="plus"
          //label="Create a new Whype"
          accessibilityLabel="Create a new Whype"
          style={styles.fab}
          onPress={showDialog}
          visible={fabVisible}
        />
      </Tooltip>
    </SafeAreaView>
  );
};

export default WhypesScreen;
