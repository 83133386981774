import React from "react";
import { ActivityIndicator } from "react-native";
import { Dialog, Portal, Text } from "react-native-paper";
import { useAuth } from "../utils/AuthContext";

import { SECONDARY_COLOR } from "../utils/styles/main";

const Indicator = ({ children, visible }) => {
  const { isLoading, setIsLoading } = useAuth();

  return (
    <>
      <Portal>
        <Dialog
          visible={isLoading}
          dismissable={true}
          dismissableBackButton={true}
          onDismiss={() => setIsLoading(false)}
          style={{ alignSelf: "center", borderRadius: 8, width: "60" }}
        >
          <Dialog.Content>
            <ActivityIndicator
              size="large"
              animating={isLoading}
              color={SECONDARY_COLOR}
              role="progressbar"
            />
            {/* <Text varian={"bodyMedium"}>Please wait...</Text> */}
          </Dialog.Content>
        </Dialog>
      </Portal>
      {children}
    </>
  );
};

export default Indicator;
