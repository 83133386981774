import React, { useEffect, useRef } from "react";
import { View, Image, Animated, StyleSheet } from "react-native";

const BreathingImage = ({ source, style }) => {
  const scaleValue = useRef(new Animated.Value(1)).current;

  const breatheAnimation = () => {
    Animated.sequence([
      Animated.timing(scaleValue, {
        toValue: 1.2, // Grow
        duration: 1000,
        useNativeDriver: false,
      }),
      Animated.timing(scaleValue, {
        toValue: 1, // Shrink back to normal
        duration: 1000,
        useNativeDriver: false,
      }),
    ]).start(() => breatheAnimation());
  };

  useEffect(() => {
    breatheAnimation();
  }, []);

  return (
    <View style={styles.container}>
      <Animated.Image
        style={{ ...styles.image, transform: [{ scale: scaleValue }] }}
        source={source}
        resizeMode={"cover"}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    //flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: 120,
    height: 120,
    //resizeMode: "cover",
  },
});

export default BreathingImage;
