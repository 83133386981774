import React from "react";

import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { MaterialCommunityIcons as Icon } from "@expo/vector-icons";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useTheme } from "../utils/themes/ThemeContext";
import { useNavigationStateContext } from "../navigation/NavigationContext";

import HomeScreen from "../screens/HomeScreen";
//import PlansScreen from "../screens/PlansScreen";
import WhypePlanScreen from "../screens/WhypePlanScreen";
import WhypesScreen from "../screens/WhypesScreen";
import WhypesHistoryScreen from "../screens/WhypesHistoryScreen";
import ProfileScreen from "../screens/ProfileScreen";
import NotificationScreen from "../screens/NotificationScreen";
import SignIn from "../screens/auth/SignIn";
import SignUp from "../screens/auth/SignUp";
import PasswordReset from "../screens/auth/PasswordReset";
import EmailChange from "../screens/auth/EmailChange";

import { useAuth } from "../utils/AuthContext";
import EditProfile from "../screens/auth/EditProfile";
import PaymentPortal from "../components/PaymentPortal";
import { BookingScreen } from "../screens/WhypeBookingScreen";
import WhypePlans from "../screens/WhypePlanScreen";
import { AdditionalServices } from "../screens/AdditionalServices";
import DeleteAccount from "../screens/auth/DeleteAccount";
import PasswordChange from "../screens/auth/PasswordChange";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "../utils/styles/main";
import ScheduleScreen from "../screens/ScheduleScreen";
import Indicator from "../components/ActivityIndicator";
import IntroScreen from "../screens/IntroScreen";
import ResponsiveWrapper from "../components/ResponsiveWrapper";

const Tab = createBottomTabNavigator();
//const tabBarHeight = useBottomTabBarHeight()

const Stack = createNativeStackNavigator();

function Whypes() {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="WhypesList">
        {(props) => (
          <ResponsiveWrapper>
            <WhypesScreen {...props} />
          </ResponsiveWrapper>
        )}
      </Stack.Screen>
      <Stack.Screen name="WhypeHistory">
        {(props) => (
          <ResponsiveWrapper>
            <WhypesHistoryScreen {...props} />
          </ResponsiveWrapper>
        )}
      </Stack.Screen>
      <Stack.Screen name="Plans">
        {(props) => (
          <ResponsiveWrapper>
            <WhypePlanScreen {...props} />
          </ResponsiveWrapper>
        )}
      </Stack.Screen>
      <Stack.Screen name="PaymentPortal">
        {(props) => (
          <ResponsiveWrapper>
            <PaymentPortal {...props} />
          </ResponsiveWrapper>
        )}
      </Stack.Screen>
      <Stack.Screen name="Booking">
        {(props) => (
          <ResponsiveWrapper>
            <BookingScreen {...props} />
          </ResponsiveWrapper>
        )}
      </Stack.Screen>
      <Stack.Screen name="CreatePlan">
        {(props) => (
          <ResponsiveWrapper>
            <WhypePlans {...props} />
          </ResponsiveWrapper>
        )}
      </Stack.Screen>
      <Stack.Screen name="Addons">
        {(props) => (
          <ResponsiveWrapper>
            <AdditionalServices {...props} />
          </ResponsiveWrapper>
        )}
      </Stack.Screen>
      <Stack.Screen name="Schedule">
        {(props) => (
          <ResponsiveWrapper>
            <ScheduleScreen {...props} />
          </ResponsiveWrapper>
        )}
      </Stack.Screen>
    </Stack.Navigator>
  );
}

function Auth() {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="SignIn">
        {(props) => (
          <ResponsiveWrapper {...props}>
            <SignIn {...props} />
          </ResponsiveWrapper>
        )}
      </Stack.Screen>
      <Stack.Screen name="SignUp">
        {(props) => (
          <ResponsiveWrapper {...props}>
            <SignUp {...props} />
          </ResponsiveWrapper>
        )}
      </Stack.Screen>
      <Stack.Screen name="PasswordReset">
        {(props) => (
          <ResponsiveWrapper {...props}>
            <PasswordReset {...props} />
          </ResponsiveWrapper>
        )}
      </Stack.Screen>
      <Stack.Screen name="PasswordChange">
        {(props) => (
          <ResponsiveWrapper {...props}>
            <PasswordChange {...props} />
          </ResponsiveWrapper>
        )}
      </Stack.Screen>
      <Stack.Screen name="EmailChange">
        {(props) => (
          <ResponsiveWrapper {...props}>
            <EmailChange {...props} />
          </ResponsiveWrapper>
        )}
      </Stack.Screen>
      <Stack.Screen name="AccountDelete">
        {(props) => (
          <ResponsiveWrapper {...props}>
            <DeleteAccount {...props} />
          </ResponsiveWrapper>
        )}
      </Stack.Screen>
    </Stack.Navigator>
  );
}

function Profile() {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="ProfileScreen">
        {(props) => (
          <ResponsiveWrapper {...props}>
            <ProfileScreen {...props} />
          </ResponsiveWrapper>
        )}
      </Stack.Screen>
      <Stack.Screen name="EditProfile">
        {(props) => (
          <ResponsiveWrapper {...props}>
            <EditProfile {...props} />
          </ResponsiveWrapper>
        )}
      </Stack.Screen>
      <Stack.Screen name="Auth">
        {(props) => (
          <ResponsiveWrapper {...props}>
            <Auth {...props} />
          </ResponsiveWrapper>
        )}
      </Stack.Screen>
    </Stack.Navigator>
  );
}

const Navigation = () => {
  const { user, notificationCount } = useAuth();
  const { navigationState, setNavigationState } = useNavigationStateContext();
  const { theme } = useTheme();

  return (
    <Indicator>
      <NavigationContainer
        theme={theme}
        initialState={navigationState}
        onStateChange={(state) => setNavigationState(state)}
      >
        {user ? (
          <Tab.Navigator>
            <Tab.Group
              screenOptions={{
                headerShown: false,
                tabBarLabelPosition: "below-icon",
                tabBarStyle: {
                  border: 0,
                  backgroundColor: theme?.colors?.surface,
                  //alignItems: "center",
                  //paddingHorizontal: "60",
                },
                // tabBarItemStyle: {
                //   marginHorizontal: "80",
                // },
                tabBarActiveTintColor: SECONDARY_COLOR,
                tabBarInactiveTintColor: theme?.colors?.primary,
              }}
            >
              <Tab.Screen
                name="Home"
                //component={HomeScreen}
                options={{
                  tabBarLabel: "Home",
                  tabBarIcon: ({ color, size }) => (
                    <Icon
                      name="home-circle-outline"
                      size={size}
                      color={color}
                    />
                  ),
                }}
              >
                {(props) => (
                  <ResponsiveWrapper>
                    <HomeScreen {...props} />
                  </ResponsiveWrapper>
                )}
              </Tab.Screen>

              <Tab.Screen
                name="Whypes"
                component={Whypes}
                options={{
                  tabBarLabel: "Whypes",
                  //tabBarBadge: "2",
                  tabBarIcon: ({ color, size }) => (
                    <Icon name="car-wash" size={size} color={color} />
                  ),
                }}
              />
              <Tab.Screen
                name="Notification"
                component={NotificationScreen}
                options={{
                  tabBarLabel: "Notification",
                  //tabBarBadge: hasNotifications,
                  tabBarBadge: notificationCount,
                  tabBarBadgeStyle: { width: "auto" },
                  tabBarHideOnKeyboard: true,
                  tabBarIcon: ({ color, size }) => (
                    <Icon name="bell-outline" size={size} color={color} />
                  ),
                }}
              />
              <Tab.Screen
                name="Profile"
                component={Profile}
                options={{
                  tabBarLabel: "Me",
                  tabBarIcon: ({ color, size }) => (
                    <Icon
                      name="account-circle-outline"
                      size={size}
                      color={color}
                    />
                  ),
                }}
              />
            </Tab.Group>
          </Tab.Navigator>
        ) : (
          <>
            <Stack.Navigator screenOptions={{ headerShown: false }}>
              <Stack.Screen name="Intro" component={IntroScreen} />
              {/* <Stack.Screen name="Intro">
                {(props) => (
                  <ResponsiveWrapper {...props}>
                    <IntroScreen {...props} />
                  </ResponsiveWrapper>
                )}
              </Stack.Screen> */}
              <Stack.Screen name="Auth">
                {(props) => (
                  <ResponsiveWrapper {...props}>
                    <Auth {...props} />
                  </ResponsiveWrapper>
                )}
              </Stack.Screen>
            </Stack.Navigator>
          </>
        )}
      </NavigationContainer>
    </Indicator>
  );
};
export default Navigation;
