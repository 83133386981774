import React, { useState } from "react";
import { View, ScrollView, RefreshControl } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Text, Button, Appbar, List, Checkbox } from "react-native-paper";

import { styles } from "../utils/styles/main";
import { useAuth } from "../utils/AuthContext";
import { StepIndicator } from "../components/BadgeComponent";
import { useTheme } from "../utils/themes/ThemeContext";

const AdditionalServices = ({ navigation, route }) => {
  const { selectedPackage, totalSteps, subTotal, purchaseType } = route.params;
  let { completedSteps } = route.params;
  completedSteps = completedSteps + 1;
  // total - subTotal
  const { loadAdditionalServices, isLoading } = useAuth();
  const [additionalServices, setAdditionalServices] = useState([]);
  const [newSubTotal, setNewSubTotal] = useState(subTotal);
  const [selectedServices, setSelectedServices] = React.useState([]);

  const handleServiceSelection = (service) => {
    // const updatedServices = selectedServices.includes(service)
    //   ? selectedServices.filter((s) => s !== service)
    //   : [...selectedServices, service];
    // setSelectedServices(updatedServices);

    if (selectedServices.includes(service)) {
      setNewSubTotal(newSubTotal - service.price);
      setSelectedServices(selectedServices.filter((s) => s !== service));
    } else {
      setNewSubTotal(newSubTotal + service.price);
      setSelectedServices([...selectedServices, service]);
    }
  };

  const handleSubmition = () => {
    // const items = [
    //   ...selectedServices,
    //   { name: selectedPackage, price: newSubTotal },
    // ];

    navigation.navigate("PaymentPortal", {
      total: newSubTotal,
      selectedPackage,
      selectedServices,
      completedSteps,
      totalSteps,
      purchaseType,
    });
  };

  const loadAdditional = async () => {
    // Load subscriptions
    try {
      const { additionalServices } = await loadAdditionalServices();

      if (additionalServices) {
        setAdditionalServices(additionalServices);
      }
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    loadAdditional();
  }, []);
  const { theme } = useTheme();

  return (
    <SafeAreaView style={styles.safeArea}>
      <Appbar.Header statusBarHeight={0}>
        <Appbar.BackAction
          onPress={() => {
            navigation.navigate("Booking");
          }}
          accessibilityLabel="Go back"
        />

        <Appbar.Content
          title="Additional services"
          titleStyle={{ fontSize: 14 }}
        />
        {/*
          <Appbar.Action icon="calendar" onPress={() => {}} />
          <Appbar.Action icon="magnify" onPress={() => {}} />
      */}
      </Appbar.Header>
      <StepIndicator totalSteps={totalSteps} completedSteps={completedSteps} />
      <ScrollView
        refreshControl={
          <RefreshControl refreshing={isLoading} onRefresh={loadAdditional} />
        }
      >
        <View style={styles.container}>
          <Text variant="bodyMedium" style={styles.text.secondary}>
            Add some extra services to get the best whyping experience.
          </Text>
          <List.Section>
            <List.Subheader style={[styles.text.secondary, styles.underline]}>
              Additional services (Optional)
            </List.Subheader>

            {additionalServices.map((service) => (
              <Checkbox.Item
                key={service.name}
                label={`${service.name}: N ${service.price}`}
                status={
                  selectedServices.includes(service) ? "checked" : "unchecked"
                }
                onPress={(e) => {
                  e.stopPropagation();
                  handleServiceSelection(service);
                }}
                style={theme.dark ? null : styles.text.primary}
                labelStyle={theme.dark ? null : styles.text.primary}
                labelVariant="bodyMedium"
                color={theme.dark ? null : styles.text.primary.color}
              />
            ))}
          </List.Section>
        </View>
      </ScrollView>
      <View style={styles.m3}>
        <Text
          variant="titleMedium"
          style={[styles.text.secondary, styles.textCenter]}
        >
          Sub-total: N {newSubTotal}
        </Text>
      </View>
      <View style={styles.btnContainer}>
        <Button
          onPress={() => handleSubmition()}
          style={styles.btn.primary}
          mode="contained"
          loading={isLoading}
          disabled={isLoading}
          textColor="white"
        >
          Continue
        </Button>
      </View>
    </SafeAreaView>
  );
};

export { AdditionalServices };
