import React, { useState } from "react";

import { Card, IconButton, TextInput } from "react-native-paper";
import { Text } from "react-native";
import { styles } from "../utils/styles/main";
import { useAuth } from "../utils/AuthContext";

const WhypersCheckpoint = () => {
  const { verifyCheckpointCode } = useAuth();
  const [checkCode, setCheckCode] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [feedback, setFeedback] = useState(
    "If you have a verification, enter it below"
  );

  const sendVerificationCode = async () => {
    if (verificationCode && verificationCode.length >= 5) {
      console.log("Sending code");
      const { message } = await verifyCheckpointCode({
        verificationCode: verificationCode,
      });
      setFeedback(message);
    }
    return;
  };

  return (
    <>
      <Card style={styles.card}>
        <Card.Title
          title={"Checkpoint - verify a whyper"}
          titleVariant="bodySmall"
          right={() => (
            <IconButton
              onPress={() => setCheckCode(!checkCode)}
              icon={"form-textbox-password"}
            />
          )}
        />
        {!checkCode && (
          <Card.Content>
            <Text
              variant="bodySmall"
              style={[styles.text.secondary, styles.pb]}
            >
              {feedback}
            </Text>
            <TextInput
              label={"Enter verification code"}
              value={verificationCode}
              style={styles.input}
              mode="outlined"
              maxLength={6}
              // inputMode="numeric"
              // keyboardType="numeric"
              onChangeText={(value) => setVerificationCode(value)}
              right={
                <TextInput.Icon
                  onPress={() => sendVerificationCode()}
                  icon={"send"}
                  disabled={!verificationCode}
                />
              }
            />
          </Card.Content>
        )}
      </Card>
    </>
  );
};

export default WhypersCheckpoint;
