import React, { useState } from "react";
import { View, Text } from "react-native";
import { Appbar, TextInput, Button } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import { useAuth } from "../../utils/AuthContext";
import ActionFeedback from "../../components/ActionFeedback";

import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  styles,
} from "../../utils/styles/main";
//import { useAuth } from "../../utils/AuthContext";

const PasswordReset = ({ navigation }) => {
  const { isLoading, user, setLoading } = useAuth();
  const [time, setTime] = useState(60);
  const [actionMsg, setActionMsg] = useState("Reset password");

  const handleResetPassword = async () => {
    // TODO: Implement password reset functionality
    //return;
  };

  const countDown = async () => {
    // TODO: Implement countdown functionality
    // Password reset email will be sent to user's email
    // User can only request another password reset email after 1 minute
    // User can only request 3 times in 1 day
    //await handleResetPassword();

    setInterval(() => {
      //setActionMsg("Resend password reset email");
      if (time != 0) setTime(time - 1);
      else {
        setTime(60 * 2);
        setActionMsg("Resend link");
      }
      setActionMsg(`Resend link (${time})`);
    }, 100);
  };

  return (
    <SafeAreaView style={{ flex: 1, justifyContent: "center" }}>
      <Appbar.Header statusBarHeight={0}>
        <Appbar.BackAction onPress={() => navigation.goBack()} />
        <Appbar.Content title="Reset password" titleStyle={{ fontSize: 14 }} />
      </Appbar.Header>
      <ActionFeedback />
      <View style={[styles.container, { justifyContent: "center" }]}>
        <Text style={styles.text.primary} variant="labelSmall">
          We'll send an email to "{user.email}" with a link to reset your
          password.
        </Text>
      </View>
      <View style={styles.btnContainer}>
        <Button
          mode="contained"
          onPress={() => countDown()}
          style={[styles.actionBtn1, { margin: 10 }]}
          loading={isLoading}
          disabled={isLoading}
          icon="lock-reset"
          textColor="white"
        >
          {actionMsg}
        </Button>
      </View>
    </SafeAreaView>
  );
};

export default PasswordReset;
