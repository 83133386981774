import { View } from "react-native";
import { Button } from "react-native-paper";
import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  styles,
  whypeStyles,
} from "../utils/styles/main";

const HomeCallToAcion = ({ navigation }) => {
  return (
    <View
      style={{
        //height: "30%",
        marginVertical: 30,
        //flex: 1,
      }}
    >
      <Button
        icon="car-wash"
        mode="contained"
        // onPress={() => navigation.navigate("Whypes", { action: "create" })}
        onPress={() => navigation.navigate("Whypes", { screen: "Booking" })}
        style={[styles.actionBtn, styles.btn.secondary]}
        textColor="white"
      >
        Book a Whype
      </Button>
      <Button
        icon="square-edit-outline"
        mode="contained"
        // onPress={() => navigation.navigate("Whypes", { action: "create" })}
        onPress={() => navigation.navigate("Whypes", { screen: "CreatePlan" })}
        style={[styles.actionBtn, styles.btn.primary]}
        textColor="white"
      >
        Create a plan
      </Button>
    </View>
  );
};

export default HomeCallToAcion;
