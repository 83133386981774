import React, { useState } from "react";
import { View, ScrollView, StyleSheet } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { Appbar, Button, Text, TextInput } from "react-native-paper";
import { useAuth } from "../../utils/AuthContext";

import {
  SECONDARY_COLOR,
  PRIMARY_COLOR,
  styles,
} from "../../utils/styles/main";
import ActionFeedback from "../../components/ActionFeedback";

const PasswordChange = ({ navigation }) => {
  const { isLoading, changePassword } = useAuth();

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(true);
  const [showCurrentPassword, setShowCurrentPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);

  const [passwordMismatch, setPasswordMismatch] = useState(false);

  const handleSubmit = async () => {
    // TODO: Implement email change logic
    if (newPassword != confirmPassword) {
      setPasswordMismatch(true);
      return;
    }
    try {
      const { message, error } = await changePassword(
        currentPassword,
        newPassword
      );
      if (!error) {
        navigation.goBack();
      }
    } catch (error) {
      return;
    }
    setPasswordMismatch(false);
  };

  return (
    <SafeAreaView style={styles.safeArea}>
      <Appbar.Header statusBarHeight={0}>
        <Appbar.BackAction onPress={() => navigation.goBack()} />
        <Appbar.Content title="Change Password" titleStyle={{ fontSize: 14 }} />
      </Appbar.Header>
      <ActionFeedback />

      <ScrollView contentContainerStyle={{ flex: 1, justifyContent: "center" }}>
        <View style={styles.container}>
          <View style={styles.headingText}>
            <Text style={styles.text.primary} variant="bodySmall">
              For your safety, you have to enter your current password. If you
              forgot your password, go back and select forgot password.
            </Text>
          </View>

          <Text style={styles.text.primary} variant="labelSmall">
            Current password:
          </Text>
          <TextInput
            label="Current password"
            value={currentPassword}
            onChangeText={setCurrentPassword}
            placeholder="Enter current password"
            mode="outlined"
            style={styles.input}
            secureTextEntry={showCurrentPassword}
            left={<TextInput.Icon icon="form-textbox-password" />}
            right={
              <TextInput.Icon
                onPress={() => setShowCurrentPassword(!showCurrentPassword)}
                icon={showCurrentPassword ? "eye-off" : "eye"}
              />
            }
          />
          <Text style={styles.text.primary} variant="labelSmall">
            New password:
          </Text>
          <TextInput
            label="New password"
            value={newPassword}
            onChangeText={setNewPassword}
            placeholder="Enter new password"
            mode="outlined"
            style={styles.input}
            secureTextEntry={showNewPassword}
            left={<TextInput.Icon icon="form-textbox-password" />}
            right={
              <TextInput.Icon
                onPress={() => setShowNewPassword(!showNewPassword)}
                icon={showNewPassword ? "eye-off" : "eye"}
              />
            }
          />
          <Text style={styles.text.primary} variant="labelSmall">
            {!passwordMismatch
              ? "Re-enter your new password."
              : "Password does not match."}
          </Text>
          <TextInput
            label="Re-enter password"
            value={confirmPassword}
            onChangeText={setConfirmPassword}
            placeholder="Enter password to confirm"
            mode="outlined"
            style={styles.input}
            secureTextEntry={showConfirmPassword}
            left={<TextInput.Icon icon="form-textbox-password" />}
            right={
              <TextInput.Icon
                onPress={() => setShowConfirmPassword(!showConfirmPassword)}
                icon={showConfirmPassword ? "eye-off" : "eye"}
              />
            }
          />
        </View>
        <View style={styles.btnContainer}>
          <Button
            onPress={handleSubmit}
            mode="contained"
            disabled={isLoading}
            loading={isLoading}
            style={[styles.actionBtn1, { margin: 10 }]}
            textColor="white"
          >
            Change Email
          </Button>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default PasswordChange;
