import React, { useState } from "react";
import { View, ScrollView, StyleSheet } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import {
  Appbar,
  Button,
  Text,
  TextInput,
  Portal,
  Dialog,
} from "react-native-paper";
import { useAuth } from "../../utils/AuthContext";

import {
  SECONDARY_COLOR,
  PRIMARY_COLOR,
  styles,
} from "../../utils/styles/main";
import ActionFeedback from "../../components/ActionFeedback";

const DeleteAccount = ({ navigation }) => {
  const { isLoading, user, accountDelete } = useAuth();

  const [reason, setReason] = useState("");
  const [password, setPassword] = useState("");
  const [dialogVisible, setDialogVisible] = React.useState(false);
  const showDialog = () => setDialogVisible(true);
  const hideDialog = () => setDialogVisible(false);
  const [promptMessage, setPromptMessage] = React.useState(
    `By deleting your account, all records associated with your account will be deleted also.
    Any pending whypes schedules or payment confirmations will no longer be valid.
    Do you want to continue?`
  );
  const handleSubmit = async () => {
    // TODO: Implement email change logic
    if (!password.length > 5 || !reason.length > 5) return;
    await accountDelete({ password, reason });
  };

  return (
    <SafeAreaView style={styles.safeArea}>
      <Appbar.Header statusBarHeight={0}>
        <Appbar.BackAction onPress={() => navigation.goBack()} />
        <Appbar.Content title="Delete account" titleStyle={{ fontSize: 14 }} />
      </Appbar.Header>
      <ActionFeedback />
      <Portal>
        <Dialog visible={dialogVisible} onDismiss={hideDialog}>
          <Dialog.Title>
            <Text variant="titleLarge">Confirm account delete</Text>
          </Dialog.Title>
          <Dialog.Content>
            <Text variant={"bodySmall"}>{promptMessage}</Text>
            <View style={styles.row}></View>
          </Dialog.Content>
          <Dialog.Actions>
            <Button
              onPress={() => {
                hideDialog();
              }}
            >
              No, cancel
            </Button>
            <Button
              onPress={() => {
                hideDialog();
                handleSubmit();
              }}
              //style={styles.actionBtn1}
              //mode="outlined"
            >
              Yes, continue
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
      <ScrollView contentContainerStyle={{ flex: 1, justifyContent: "center" }}>
        <View style={styles.container}>
          <View style={styles.headingText}>
            <Text style={styles.text.primary} variant="bodySmall">
              We're sad you are leaving us. If you need help with anything
              please reach out to us and we'll be sure to sort it out! But if
              you've made up your mind, please fill the form below.
            </Text>
          </View>

          <Text style={styles.text.primary} variant="labelSmall">
            Please tell us what went wrong or why you are leaving us
          </Text>
          <TextInput
            label="Reason"
            value={reason}
            onChangeText={setReason}
            placeholder="Please tell us why you are deleting your account"
            mode="outlined"
            style={styles.input}
            multiline
          />

          <Text style={styles.text.primary} variant="labelSmall">
            You will need to confirm your password to continue.
          </Text>
          <TextInput
            label="Password"
            value={password}
            onChangeText={setPassword}
            placeholder="Enter password to confirm"
            mode="outlined"
            style={styles.input}
          />
        </View>
        <View style={styles.btnContainer}>
          <Button
            onPress={showDialog}
            mode="contained"
            disabled={isLoading}
            loading={isLoading}
            style={[styles.actionBtn1, { margin: 10 }]}
            textColor="white"
          >
            Delete my account
          </Button>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default DeleteAccount;
