import React from "react";
import { Text, Dimensions, StyleSheet, View, Image } from "react-native";
import { Button } from "react-native-paper";
import { SwiperFlatList } from "react-native-swiper-flatlist";
import { useAuth } from "../utils/AuthContext";
import { PRIMARY_COLOR, SECONDARY_COLOR, styles } from "../utils/styles/main";
import { SafeAreaView } from "react-native-safe-area-context";

const IntroScreen = ({ navigation }) => {
  const { width, height } = Dimensions.get("window");

  return (
    <SafeAreaView style={componentStyles.container}>
      <View style={[styles.m3, { alignSelf: "flex-end" }]}>
        <Button
          onPress={() => navigation.navigate("Auth")}
          icon={"arrow-right"}
          contentStyle={{ flexDirection: "row-reverse" }}
          textColor={PRIMARY_COLOR}
        >
          Skip
        </Button>
      </View>
      <SwiperFlatList
        autoplay
        autoplayDelay={5}
        onEndReached={() => navigation.navigate("Auth")}
        //autoplayLoop
        index={0}
        showPagination
        paginationActiveColor={PRIMARY_COLOR}
        paginationDefaultColor={SECONDARY_COLOR}
        paginationStyle={styles.pagination}
        // paginationStyle={[componentStyles.p3, { top: 0 }]}
        paginationStyleItem={{ width: 10, height: 10 }}
        centerContent={true}
      >
        <View style={componentStyles.child}>
          <Image
            style={componentStyles.image}
            source={require("../assets/images/unboarding/1.jpg")}
          />
        </View>
        <View style={componentStyles.child}>
          <Image
            style={componentStyles.image}
            source={require("../assets/images/unboarding/2.jpg")}
          />
        </View>
        <View style={componentStyles.child}>
          <Image
            style={componentStyles.image}
            source={require("../assets/images/unboarding/3.jpg")}
          />
        </View>
        <View style={componentStyles.child}>
          <Image
            style={componentStyles.image}
            source={require("../assets/images/unboarding/4.jpg")}
          />
        </View>
      </SwiperFlatList>
    </SafeAreaView>
  );
};

const { width, height } = Dimensions.get("window");

const componentStyles = StyleSheet.create({
  container: { flex: 1, backgroundColor: "white" },
  //   child: { width, justifyContent: "center" },
  child: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },

  image: {
    width,
    height,
    resizeMode: "center",
    alignSelf: "center",
  },
  // image: { width: "100%", height: "100%", resizeMode: "cover" },
  // pagination: { bottom: 20 },
  pagination: { top: 0 },
  skipButtonContainer: {
    position: "absolute",
    top: 40,
    right: 20,
  },
  skipButton: { color: "#555", fontSize: 16 },
  skipButtonText: { color: "#555", fontSize: 16 },
});

export default IntroScreen;
